import { useState } from 'react';

const PhotoGallery = ({ images }) => {
  // Define a stock image URL to be used as a fallback
  const stockImage = 'https://via.placeholder.com/800x600?text=No+Images+Available';

  // Determine the initial image to display
  const initialImage = images.length > 0 ? `https://api.case-trackr.com${images[0].image}` : stockImage;
  const [currentImage, setCurrentImage] = useState(initialImage);

  // Function to handle thumbnail clicks
  const handleImageClick = (url) => {
    setCurrentImage(url);
  };

  return (
    <div className="flex flex-col items-center justify-center">
      {/* Main image display */}
      <div className="w-full rounded-xl sm:h-full sm:w-fit md:h-[670px] md:w-fit lg:h-[600px] lg:w-[800px] xl:h-[555px] xl:w-[555px] 2xl:w-full 3xl:h-[745px]">
        <img
          src={currentImage}
          className="h-full w-full rounded-xl"
          alt="Selected"
        />
      </div>

      {/* Thumbnail gallery */}
      <div className="mt-[10px] flex w-full items-center justify-between rounded-[20px] px-3 py-3 hover:cursor-pointer lg:justify-center xl:px-0 3xl:gap-[14px]">
        {images.length === 0 ? (
          <p className="text-lg font-semibold">No additional images available</p>
        ) : (
          images.map((image, index) => {
            const imageUrl = `https://api.case-trackr.com${image.image}`;

            return (
              <div
                key={index}
                onClick={() => handleImageClick(imageUrl)}
                className="h-[42px] w-[42px] md:h-[104px] md:w-[104px] lg:h-[70px] lg:w-[70px] xl:h-[90px] xl:w-[90px] 3xl:h-[130px] 3xl:w-[135px]"
              >
                <img
                  src={imageUrl}
                  alt={`Image ${index + 1}`}
                  className="h-full w-full rounded-xl"
                />
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default PhotoGallery;
