export const columnsDataOrders = [
  {
    Header: 'Name',
    accessor: 'name',
  },

  {
    Header: 'Date of Birth',
    accessor: 'dob',
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Phone Number',
    accessor: 'phone',
  },
  {
    Header: '1st Line Address',
    accessor: 'first_address',
  },
  {
    Header: '2nd Line Address',
    accessor: 'second_address',
  },
  {
    Header: 'City',
    accessor: 'city',
  },
  {
    Header: 'Postcode',
    accessor: 'postcode',
  },
  {
    Header: 'Country',
    accessor: 'country'
  },
  {
    Header: 'ACTIONS',
    accessor: 'actions',
  }

];
