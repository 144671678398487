export const todoColumns = [
    {
        Header: 'Title',
        accessor: 'task',
    },
    {
        Header: 'Description',
        accessor: 'task_description',

    },
    {
        Header: 'Type',
        accessor: 'task_type',
    },
    {
        Header: 'Priority',
        accessor: 'task_priority',
    },
    {
        Header: 'Due Date',
        accessor: 'task_end_date',
    },
    {
        Header: 'Action',
        accessor: 'actionURL',
    },
];
