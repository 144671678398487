import React, { useState, useEffect } from "react";
import InputField from "components/fields/InputField";
import TextField from "components/fields/TextField";
import DropDownNew from "components/fields/dropdownNew";
import DatePickerInput from "components/fields/DatePicker";
import { set } from "date-fns";

const MachineSelection = ({ data, drivers }) => {
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [machineSelection, setMachineSelection] = useState("");
  const [driverSelection, setDriverSelection] = useState("");
  const [driverName, setDriverName] = useState("");
  useEffect(() => {

    // Function to save the current state to sessionStorage
    const saveToSessionStorage = () => {
      const selectionData = {
        start: startDate.toISOString(),
        end: endDate.toISOString(),
        vehicleReg: machineSelection,
        driver: driverSelection,
        driverName: driverName
      };
      sessionStorage.setItem("selectionData", JSON.stringify(selectionData));
      console.log("Saved to sessionStorage:", selectionData);
    };

    // Save to sessionStorage whenever any of the dependencies change
    saveToSessionStorage();
  }, [startDate, endDate, machineSelection, driverSelection]); // Dependencies

  const handleVehicleChange = (e) => {
    setMachineSelection(e.target.value);

  };

  const handleDriverChange = (e) => {
    const selectedIndex = e.target.options.selectedIndex;
    const label = e.target.options[selectedIndex].text;

    setDriverSelection(e.target.value);
    setDriverName(label);

  };

  const vehicleOptions = data.map(vehicle => ({
    label: vehicle.vehicleReg,  // Display value
    value: vehicle.vehicleReg   // Actual value to be sent or used
  }));

  const driverOptions = drivers.map(driver => ({
    label: driver.driverName,  // Display value
    value: driver.id   // Actual value to be sent or used
  }));

  return (
    <div className="h-full w-full rounded-[20px] px-3 pt-7 md:px-8">
      <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
        Vehicle & Dates
      </h4>

      <div className="mt-6 grid grid-cols-2 gap-3">
        <div className="col-span-2 md:col-span-1">
          <DatePickerInput
            extra="mb-4"
            label="Start"
            placeholder={startDate}
            id="start"
            type="text"
            onChange={(date) => { setStartDate(date); }}
          />
          <DropDownNew
            extra="mb-3"
            label="Vehicle"
            placeholder="Select Vehicle"
            id="vehicle"
            type="text"
            options={vehicleOptions}
            value={machineSelection}
            onChange={handleVehicleChange}
          />
        </div>

        <div className="col-span-2 md:col-span-1">
          <DatePickerInput
            extra="mb-4"
            label="End"
            placeholder={endDate}
            id="end"
            type="text"
            onChange={(date) => { setEndDate(date); }}
          />
          <DropDownNew
            extra="mb-3"
            label="Driver"
            placeholder="Select Driver"
            id="driver"
            type="text"
            options={driverOptions}
            value={driverSelection}
            onChange={handleDriverChange}
          />
        </div>
      </div>
    </div>
  );
};

export default MachineSelection;
