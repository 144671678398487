import axios from "axios";

const instance = axios.create({
    baseURL: 'https://api.case-trackr.com/api/',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },
    withCredentials: true, // This is important for cookies and session handling
});

export default instance;