import InputField from "components/fields/InputField";
import Default from "layouts/auth/types/Default";
import { FcGoogle } from "react-icons/fc";
import Checkbox from "components/checkbox";
import { useState } from "react";
import axios from "axiosInstance";
import { useNavigate } from "react-router-dom";
import SolidSubtleMultiAlert from "views/admin/main/others/notifications/components/SolidSubtleMultiAlert";
import SolidSubtleAlert from "views/admin/main/others/notifications/components/SolidSubtleAlert";
import { AiFillExclamationCircle } from "react-icons/ai";
import { BsFillCheckCircleFill } from "react-icons/bs";
function SignInDefault() {
  const [email, setEmail] = useState(null)
  const [password, setPassword] = useState(null)
  const [showError, setShowError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const navigate = useNavigate();
  const login = () => {
    if (email != null && password != null) {
      let username = email.target.value
      let pw = password.target.value

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'auth/',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        },
        data: {
          "username": username,
          "password": pw
        }
      };
      const loginRequest = axios.request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          sessionStorage.setItem("access_token", response.data.token)
          axios.get("user/", {
            headers: {
              "Authorization": "Token " + response.data.token, Accept: 'application/json',
              'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            }
          }).then((res) => {
            console.log(res.data.users[0])
            console.log(res.data.users[0].company.companyName)
            sessionStorage.setItem("first_name", res.data.users[0].first_name)
            sessionStorage.setItem("last_name", res.data.users[0].last_name)
            sessionStorage.setItem("company", res.data.users[0].company.companyName)
            navigate("/auth/verification/mfa")
          })

        })
        .catch((error) => {

          console.log(error);
          if (error.code == "ERR_NETWORK") {
            setErrorMessage("An unexpected error occurred.")
            setShowError(true)
          }
          else {
            setErrorMessage("Enter a valid username and password")
            setShowError(true)
          }

        });
    } else {
      setErrorMessage("Enter a valid username and password")
      setShowError(true)
    }
  }
  return (
    <Default
      maincard={
        <div className="mt-24 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
          {/* Sign in section */}
          <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
            <h3 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
              Sign In
            </h3>
            <p className="mb-9 ml-1 text-base text-gray-600">
              Enter your email and password to sign in!
            </p>

            <div className="mb-6 flex items-center">
              <div className="h-px w-full bg-gray-200 dark:!bg-navy-700" />

              <div className="h-px w-full bg-gray-200 dark:!bg-navy-700" />
            </div>
            {/* Email */}
            <InputField
              variant="auth"
              extra="mb-3"
              label="Email"
              placeholder="info@case-trackr.com"
              id="email"
              type="text"
              onChange={setEmail}
            />

            {/* Password */}
            <InputField
              variant="auth"
              extra={`mb-3`}
              label="Password"
              placeholder="password"
              id="password"
              type="password"
              onChange={setPassword}
            />
            {/* Checkbox */}
            <div className="mb-4 flex items-center justify-between px-2">

              <a
                className="text-sm font-medium text-blue-950 hover:text-brand-600 dark:text-white"
                href=" "
              >
                Forgot Password?
              </a>
            </div>
            <button className={"w-full rounded-xl bg-blue-950 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"} onClick={() => {
              login()
            }}>
              Sign In
            </button>
            <div className={`mt-6 ${showError === false
              ? "hidden"
              : "visible"
              } `}>


              <SolidSubtleAlert
                title="Error"
                description={errorMessage}
                icon={<AiFillExclamationCircle />}
                iconColor="text-red-500"
                closeBg="hover:bg-white/20 text-navy-700 dark:text-white hidden"
                bg="bg-[#FDE0D0] dark:!bg-navy-700"
                mb="mb-14"

              />
            </div>
          </div>
        </div>
      }
    />
  );
}

export default SignInDefault;
