import { MdOutlineCalendarToday } from "react-icons/md";
import React, { useMemo, useState, useEffect } from "react";
import InputField from "components/fields/InputField";
import TextField from "components/fields/TextField";
import DatePickerInput from "components/fields/DatePicker";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import Card from "components/card";

import { IoEyeOutline } from "react-icons/io5";
import { BiTrash } from "react-icons/bi";
import { AiOutlinePlus, } from "react-icons/ai";
import Modal from 'react-modal';
import axios from "axiosInstance";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DropDownNew from "components/fields/dropdownNew";
import { set } from "date-fns";
Modal.setAppElement('#root');
function padID(id) {
  return String(id).padStart(4, '0');
}
function FormSubmissions(props) {
  const { columnsData, tableData, caseID } = props;
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const [isLoading, setIsLoading] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [formOptions, setFormOptions] = useState([]);
  const [date, setDate] = useState(new Date());
  const [formURL, setFormURL] = useState(null);
  const [sessionName, setSessionName] = useState("");
  const [sessionNotes, setSessionNotes] = useState(null);
  const access_token = sessionStorage.getItem("access_token");
  const [viewModalIsOpen, setViewModelIsOpen] = useState(false);
  const [form, setForm] = useState(null);
  const headers = {
    "Authorization": "Token " + access_token,
    "Accept": 'application/json',
    'Content-Type': 'application/json',
  }
  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };
  useEffect(() => {
    axios.get("case/forms/", { headers }).then((response) => {
      if (response.status == 200) {
        console.log(response.data);
        const forms = response.data.forms;
        const transformedOptions = forms.map(form => ({
          label: form.form_name,
          value: form.id
        }));
        setFormOptions(transformedOptions);
        setIsLoading(false);
      }
    });
  }, []);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const BillingOptions = [
    { label: "Enabled", value: 1 },
    { label: "Disabled", value: 0 },

  ];
  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    tableInstance;
  const color1 = " text-green-500 font-medium";
  const color2 = " text-red-500 font-medium";
  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };
  const handleSubmission = () => {
    const form_id = selectedOption;
    const expiration_date = date;
    const case_id = caseID;
    const session_name = sessionName;
    const session_notes = sessionNotes;
    axios.post("case/forms/session/", { form_id, expiration_date, case_id, session_name, session_notes }, { headers }).then((response) => {
      if (response.status == 201) {
        console.log(response.data);
        const sessionID = response.data.id
        setFormURL("https://dashboard.case-trackr.com/guest/form/" + sessionID)
        toast.success("Form link created successfully");
      }
    }).catch((error) => {
      console.log(error);
      toast.error("An error occurred");
    });
  }
  const openViewModal = (session_id) => {
    axios.get("case/forms/session/?id=" + session_id, { headers }).then((response) => {
      if (response.status === 200) {
        setForm(response.data);
        setViewModelIsOpen(true);
      }
    });

  };
  const closeViewModal = () => {
    setViewModelIsOpen(false);
  };
  return (
    <Card extra={"h-full w-full pt-3 pb-10 px-8"}>
      <div className="mt-2 flex w-full items-center justify-between px-1">
        <h4 className="mb-[22px] ml-px text-lg font-bold text-navy-700 dark:text-white">
          Form Submissions
        </h4>
        <button onClick={openModal} className="linear flex items-center justify-center rounded-md bg-lightPrimary px-8 py-1 text-lg text-blue-950 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:!bg-navy-700 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
          <AiOutlinePlus />
        </button>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Add Parent"
        className="fixed inset-0 flex items-center justify-center"
        overlayClassName="fixed inset-0 flex justify-center items-center"
      >
        <div className="w-xl max-w-xl rounded-[20px] px-3 pt-7 md:px-8 bg-white bg-clip-border shadow-xl">
          {/* Header */}
          <div >
            <h4 className="mb-[2px] ml-px text-lg font-bold text-navy-700 dark:text-white">Create a form link</h4>
            <h4 className="mb-[22px] ml-px text-sm font-light text-navy-700 dark:text-white">This allows you to create a link to send to people to retrieve information relevant to this case. NOTE: Session notes are visable to all users</h4>
          </div>
          {/* content */}
          {/* inputs */}
          <div className="mt-6 grid grid-cols-2 gap-3">
            <div className="col-span-2 md:col-span-1">
              <InputField
                label="Session Name"
                placeholder="Example Session Name"
                id="sessionName"
                type="text"

                disabled={false}
                onChange={(e) => setSessionName(e.target.value)}
              />
              <DropDownNew
                extra="mb-3"
                label="Form"
                id="contactType"
                placeholder="Select a form"
                options={formOptions}
                value={selectedOption}
                onChange={handleSelectChange}
              />

            </div>
            {/* right side */}
            <div className="col-span-2 md:col-span-1">
              <DatePickerInput
                extra="mb-4"
                label="Form Expiration Date"
                placeholder={date}
                id="date"
                type="text"
                onChange={(date) => { setDate(date); }}
              />
              <TextField
                label="Session Notes"
                placeholder="E.g fill out all fields, or these are the instructions etc."
                id="eventDescription"
                type="text"
                rows={6}
                disabled={false}
                onChange={(e) => setSessionNotes(e.target.value)}
              />
              <div className={`${formURL ? "" : "hidden"}`}>
                <h4 className="mb-[2px] ml-px text-lg font-bold text-navy-700 dark:text-white">{formURL}</h4>
              </div>
            </div>

            <div className="pt-8 pb-8 flex-row flex">

              <button
                onClick={closeModal}
                className="cursor-pointer rounded-xl bg-blue-950 px-16 py-2 font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:ml-2"
              >
                Close
              </button>
              <button
                onClick={handleSubmission}
                className={`cursor-pointer rounded-xl bg-blue-950 px-16 py-2 font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:ml-2 ${formURL ? "hidden" : ""}`}
              >
                Create
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={viewModalIsOpen}
        onRequestClose={closeViewModal}
        contentLabel="Add Parent"
        className="fixed inset-0 flex items-center justify-center"
        overlayClassName="fixed inset-0 flex justify-center items-center"
      >
        <div className="w-2xl max-w-2xl rounded-[20px] px-3 pt-7 md:px-8 bg-white bg-clip-border shadow-xl">
          {/* Header */}

          <div >
            <h4 className="mb-[22px] ml-px text-lg font-bold text-navy-700 dark:text-white" x>View Form Submission</h4>
          </div>
          {/* content */}
          {/* inputs */}
          <div className="mt-6 grid grid-cols-1 gap-3">

            <form>
              {form && form.data && Object.keys(form.data).map((key, index) => {
                if (key !== "id") {
                  return (
                    <div key={index}>
                      <label className='text-sm text-navy-700 dark:text-white'>{key}</label>
                      <input
                        className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                        value={form.data[key]} disabled={true}
                      />
                    </div>
                  );
                }
                return null;
              })}

            </form>



            <div className="pt-8  pb-8 flex-row flex">
              <button
                onClick={closeViewModal}
                className="cursor-pointer rounded-xl bg-blue-950 px-16 py-2 font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:ml-2"
              >
                Close
              </button>

            </div>
          </div>
        </div>
      </Modal>
      <div className="overflow-x-scroll 2xl:overflow-x-hidden">
        <table {...getTableProps()} className="w-full">
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="px-[14px] pt-[0px]"
                    key={index}
                  >
                    <div className="text-left text-xs font-bold uppercase tracking-wide text-gray-600">
                      {column.render("Header")}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()} className="w-full">
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr className="w-full" {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {

                    let data = "";
                    if (cell.column.Header === "Session Name") {
                      data = (
                        <div className="flex items-center text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </div>
                      );
                    } else if (cell.column.Header === "Submission Date") {
                      data = (
                        <div className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </div>
                      );
                    }
                    else if (cell.column.Header === "ID") {

                      data = (
                        <div className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </div>
                      );
                    }
                    else if (cell.column.Header === "Company / Local Authority") {

                      data = (
                        <div className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </div>
                      );
                    }
                    else if (cell.column.Header === "Billing") {
                      console.log(cell.value);
                      if (cell.value === true) {
                        var test = "Enabled";
                      }
                      else {
                        test = "Disabled";
                      }
                      data = (
                        <div className="text-sm font-bold text-navy-700 dark:text-white">
                          {test}
                        </div>
                      );
                    }
                    else if (cell.column.Header === "") {
                      data = (
                        <div>
                          <button onClick={() => { openViewModal(row.original.form_session) }} className="max-h-fit w-full max-w-max cursor-pointer text-xl text-gray-600">
                            <IoEyeOutline />
                          </button>
                          <button className="max-h-fit w-full max-w-max cursor-pointer text-xl text-red-600 pl-[12px]">
                            <BiTrash />
                          </button>
                        </div>

                      );
                    }
                    return (
                      <td
                        {...cell.getCellProps()}
                        key={index}
                        className="font-xs px-[14px] pt-[19px] pb-[4px]"
                      >
                        {data}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <ToastContainer />
    </Card>
  );
}

export default FormSubmissions;
