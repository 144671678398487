import InputField from "components/fields/InputField";
import Default from "layouts/auth/types/Default";
import React from "react";
import axios from "axiosInstance";
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function ResetPassword() {
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const { id } = useParams();
  const handleSubmit = (e) => {
    e.preventDefault();
    if (password === confirmPassword) {
      // send request to reset password
      const data = {
        password: password,
        reset_code: id
      }
      axios.put("/password/reset/", data).then((response) => {
        console.log(response);
        toast.success('Password reset successfully!');
      }).catch((error) => {
        console.error('Error resetting password:', error);
        toast.error('Error resetting password');
      })
    }
  }
  return (
    <Default
      maincard={
        <div className="mb-16 flex h-full w-full items-center justify-center md:mx-0 md:px-0 lg:mb-10 lg:items-start lg:justify-start">
          {/* Sign in section */}
          <div className="mt-[16vh] flex flex-col rounded-[20px] pb-16 pt-12 md:pr-6 lg:max-w-[90%] lg:pr-0 xl:max-w-[42%] xl:pr-24">
            <h2 className="mb-2 text-4xl font-bold text-navy-700 dark:text-white">
              Reset Your Password
            </h2>
            <p className="mt-[10px] ml-1 mb-8 text-base text-gray-600">
              Enter your new secure password below!
            </p>
            {/* Email */}
            <InputField
              variant="auth"
              label="Password"
              placeholder="Enter your new password"
              id="email"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <InputField
              variant="auth"
              label="Confirm Password"
              placeholder="confirm your new password"
              id="email"
              type="password"
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            {/* button */}
            <button onClick={handleSubmit} className="mt-4 w-full rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
              Reset Password
            </button>
          </div>
          <ToastContainer />
        </div>
      }
    />
  );
}

export default ResetPassword;
