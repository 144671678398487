import { MdOutlineCalendarToday } from "react-icons/md";
import React, { useMemo, useState } from "react";
import Card from "components/card";
import formatDate from "components/actions/ConvertDate";
import InputField from "components/fields/InputField";
import { AiFillExclamationCircle } from "react-icons/ai";
import { BsPencil } from "react-icons/bs";
import TextField from "components/fields/TextField";
import Modal from 'react-modal';
import DropDownNew from "components/fields/dropdownNew";
import DatePickerInput from "components/fields/DatePicker";
import axios from "axiosInstance";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { set } from "date-fns";
Modal.setAppElement('#root');

function CaseInformation(props) {
  const { cases } = props;
  const [status, setStatus] = React.useState(1);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [caseName, setCaseName] = useState("");
  const [familyName, setFamilyName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [loiReceivedDate, setLoiReceivedDate] = useState("");
  const [assessmentType, setAssessmentType] = useState("");
  const [billableHours, setBillableHours] = useState("");
  const [localAuthority, setLocalAuthority] = useState("");
  const [reportFilingDate, setReportFilingDate] = useState("");
  const [hearingDate, setHearingDate] = useState("");
  const [bundleReceivedDate, setBundleReceivedDate] = useState("");
  const [caseDescription, setCaseDescription] = useState("");
  const access_token = sessionStorage.getItem("access_token");
  const color1 = " text-green-500 font-medium";
  const color2 = " text-red-500 font-medium";
  const options = [
    { label: "ACTIVE", value: 1 },
    { label: "PENDING", value: 2 },
    { label: "ON HOLD", value: 3 },
    { label: "PENDING PAYMENT", value: 4 },
    { label: "CLOSED", value: 5 },
  ];
  const openModal = () => {
    setCaseName(cases.case_name);
    setFamilyName(cases.case_family_name);
    setStartDate(cases.case_start_date);
    setLoiReceivedDate(cases.case_loi_received_date);
    setAssessmentType(cases.assessment_type);
    setBillableHours(cases.billable_hours);
    setLocalAuthority(cases.case_local_authority);
    setReportFilingDate(cases.case_filing_date);
    setHearingDate(cases.case_end_date);
    setBundleReceivedDate(cases.case_bundle_received_date);
    setCaseDescription(cases.case_description);
    if (cases.case_bundle_received_date === null) {
      setBundleReceivedDate(new Date());
    }
    if (cases.case_end_date === null) {
      setHearingDate(new Date());
    }
    if (cases.case_filing_date === null) {
      setReportFilingDate(new Date());
    }
    if (cases.case_loi_received_date === null) {
      setLoiReceivedDate(new Date());
    }
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };
  const submitCase = async () => {

    try {
      if (access_token) {
        const headers = {
          "Authorization": "Token " + access_token,
          "Accept": 'application/json',
          'Content-Type': 'application/json',
        }
        const response = await axios.put(`/case/${cases.id}/`, {
          case_name: caseName,
          case_family_name: familyName,
          case_status: status,
          case_start_date: startDate,
          case_loi_received_date: loiReceivedDate,
          assessment_type: assessmentType,
          billable_hours: billableHours,
          case_local_authority: localAuthority,
          case_filing_date: reportFilingDate,
          case_end_date: hearingDate,
          case_bundle_received_date: bundleReceivedDate,
          case_description: caseDescription,
        }, { headers: headers });
        if (response.status === 200) {
          toast.success("Case updated successfully");
          closeModal();
        }
      }
    } catch (error) {
      console.log(error);
      toast.error("An error occured while updating case");
    }
  }
  return (
    <Card extra={"h-full w-full pt-3 pb-10 px-8"}>

      <div className="flex items-center justify-between">
        <p className="text-lg font-bold text-navy-700 dark:text-white">
          Case Overview
        </p>
        <div className="linear flex items-center justify-center rounded-md  px-8 py-1 text-lg text-blue-950 transition duration-200  active:bg-gray-200 dark:!bg-navy-700 dark:bg-white/5 dark:text-white  dark:active:bg-white/20">
          <button onClick={openModal} className="linear flex items-center justify-center rounded-md bg-lightPrimary px-8 py-1 text-lg text-blue-950 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:!bg-navy-700 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
            <BsPencil />
          </button>
        </div>
      </div>

      <div className="mt-6 grid grid-cols-2 gap-3">
        <div className="col-span-2 md:col-span-1">

          <InputField
            label="Case Name"
            id="caseName"
            type="text"
            value={cases.case_name}
            disabled={true}
          />
          <InputField
            label="Family Name"
            id="familyName"
            type="text"
            value={cases.case_family_name}
            disabled={true}
          />
          <InputField
            label="Start Date"
            id="familyName"
            type="text"
            value={cases.case_start_date}
            disabled={true}
          />

          <InputField
            label="LOI Received Date"
            id="loiReceivedDate"
            type="text"
            value={cases.case_loi_received_date}
            disabled={true}
          />

          <InputField
            label="Assessment Type"
            id="assessmentType"
            type="text"
            value={cases.assessment_display}
            disabled={true}
          />
          <InputField
            label="Billable Hours"
            id="billableHours"
            type="text"
            value={cases.time_worked_display}
            disabled={true}
          />
        </div>
        <div className="col-span-2 md:col-span-1">

          <InputField
            label="Local Authority / Instructed By"
            id="localAuthority"
            type="text"
            value={cases.case_local_authority}
            disabled={true}
          />
          <InputField
            label="Report Filing Date"
            id="familyName"
            type="text"
            value={cases.case_filing_date}
            disabled={true}
          />
          <InputField
            label="Hearing Date"
            id="familyName"
            type="text"
            value={cases.case_end_date}
            disabled={true}
          />
          <InputField
            label="Bundle Received Date"
            id="bundleReceivedDate"
            type="text"
            value={cases.case_bundle_received_date}
            disabled={true}
          />
          <TextField
            label="Case Description"
            id="caseDescription"
            type="text"
            value={cases.case_description}
            disabled={true}
            rows={6}
          />

        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Add Parent"
        className="fixed inset-0 flex items-center justify-center"
        overlayClassName="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center"
      >
        <div className="w-3xl max-w-3xl rounded-[20px] px-3 pt-7 md:px-8 bg-white bg-clip-border shadow-xl">
          {/* Header */}

          <div >
            <h4 className="mb-[22px] ml-px text-lg font-bold text-navy-700 dark:text-white">Contact Log</h4>
          </div>
          {/* content */}
          {/* inputs */}
          <div className="mt-6 grid grid-cols-2 gap-3">
            <div className="col-span-2 md:col-span-1">

              <InputField
                label="Case Name"
                placeholder={caseName}
                id="title"
                type="text"

                onChange={(e) => { setCaseName(e.target.value); }}
              />
              <InputField
                label="Family Name"
                placeholder={familyName}
                id="title"
                type="text"

                onChange={(e) => { setFamilyName(e.target.value); }}
              />
              <DropDownNew
                extra="mb-3"
                label="Status"
                id="caseStatus"
                value={cases.case_status}
                options={options}

                onChange={(event) => { setStatus(event.target.value) }}
              />
              <TextField
                label="Case Description"
                placeholder={caseDescription}
                id="eventDescription"
                type="text"
                rows={6}
                disabled={false}
                onChange={(e) => setCaseDescription(e.target.value)}
              />
            </div>
            {/* right side */}
            <div className="col-span-2 md:col-span-1">
              <InputField
                label="Local Authority / Instructed By"
                placeholder={localAuthority}
                id="eventDescription"
                type="text"

                disabled={false}
                onChange={(e) => setLocalAuthority(e.target.value)}
              />
              <DatePickerInput

                label="Start Date"
                placeholder={startDate}
                id="date"
                type="text"
                onChange={(date) => { setStartDate(date); }}
              />
              <DatePickerInput

                label="Report Filing Date"
                placeholder={reportFilingDate}
                id="date"
                type="text"
                onChange={(date) => { setStartDate(date); }}
              />
              <DatePickerInput

                label="Hearing Date"
                placeholder={hearingDate}
                id="date"
                type="text"
                onChange={(date) => { setHearingDate(date); }}
              />
              <DatePickerInput

                label="LOI Recieved Date"
                placeholder={loiReceivedDate}
                id="date"
                type="text"
                onChange={(date) => { setLoiReceivedDate(date); }}
              />
              <DatePickerInput

                label="Bundle Recieved Date"
                placeholder={bundleReceivedDate}
                id="date"
                type="text"
                onChange={(date) => { setBundleReceivedDate(date); }}
              />
            </div>

            <div className="pt-8  pb-8 flex-row flex">
              <button
                onClick={closeModal}
                className="cursor-pointer rounded-xl bg-blue-950 px-16 py-2 font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:ml-2"
              >
                Close
              </button>
              <button
                onClick={submitCase}
                className="cursor-pointer rounded-xl bg-blue-950 px-16 py-2 font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:ml-2"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </Card>
  );
}

export default CaseInformation;
