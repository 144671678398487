export const tableCases = [
  {
    Header: 'Case Ref',
    accessor: 'id',
  },
  {
    Header: 'Start Date',
    accessor: 'case_start_date',

  },
  {
    Header: 'Status',
    accessor: 'status_display',
  },
  {
    Header: 'Family Name',
    accessor: 'case_family_name',
  },
  {
    Header: 'Outstanding Tasks',
    accessor: 'case_tasks',
  },
  {
    Header: '',
    accessor: 'button',
  },
];
