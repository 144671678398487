// Custom components

import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
function DatePickerInput(props) {
  const { label, id, extra, type, placeholder, variant, state, disabled, onChange } =
    props;

  return (
    <div className={`${extra}`}>
      <label
        htmlFor={id}
        className={`text-sm text-navy-700 dark:text-white ${variant === "auth" ? "ml-1.5 font-medium" : "ml-3 font-bold"
          }`}
      >
        {label}
      </label>
      <div className={`mt-2 flex h-12 w-full  rounded-xl border bg-white/0 p-3 text-sm outline-none"
        }`}>
        <DatePicker selected={placeholder} onChange={onChange}

          id={id}
          disabled={disabled}
          type={type}
          dateFormat="dd/MM/yyyy"
          placeholder={placeholder}

        />
      </div>

    </div>
  );
}

export default DatePickerInput;
