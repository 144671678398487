import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from "axiosInstance";
import Card from "components/card";
import { AiOutlinePlus } from "react-icons/ai";
import { IoEyeOutline } from "react-icons/io5";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { set } from 'date-fns';
const GuestFormPage = () => {
  const { id } = useParams();
  const [form, setForm] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({});
  const [sessionNotes, setSessionNotes] = useState("");
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  useEffect(() => {
    const fetchForm = async () => {
      try {
        const response = await axios.get(`case/forms/session/?id=${id}`);
        console.log(response.data)
        setForm(response.data.forms);
        setSessionNotes(response.data.session_notes);
      } catch (err) {
        setError(err.response?.data?.error || 'An error occurred');
      } finally {
        setLoading(false);
      }
    };

    fetchForm();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`case/submit-form/`, { id, ...formData });
      toast.success("Form submitted successfully");
    } catch (err) {
      alert('Error submitting form');
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    if (error == "Session has expired") {
      return (
        <Card extra={"w-full px-9 py-3 h-full"}>

          <div className="mt-2 flex w-full items-center justify-between px-1">
            <div
              className={`mt-1 ml-1 h-2.5 font-poppins text-[26px] font-bold uppercase text-grey-950 dark:text-white`}

            >
              Case <span className="font-medium text-blue-950">Trackr</span>
            </div>
          </div>

          <div className="mt-20 flex w-full items-center justify-between px-1" >
            <h4 className="font-dm text-xl font-bold text-center text-navy-700 dark:text-white">
              This session has expired. Please contact the admin to get a new link.
            </h4>
          </div>
        </Card>
      );
    }
    return <div>{error}</div>;
  }


  return (

    <Card extra={"w-full px-9 py-3 h-full"}>

      <div className="mt-2 flex w-full items-center justify-between px-1">

        <h4 className="font-dm text-xl font-bold text-navy-700 dark:text-white">
          {form.form_company_display}
        </h4>

        <div
          className={`mt-1 ml-1 h-2.5 font-poppins text-[26px] font-bold uppercase text-grey-950 dark:text-white`}

        >
          Case <span className="font-medium text-blue-950">Trackr</span>
        </div>
      </div>
      <h4 className="font-dm text-sm font-light text-navy-700 dark:text-white">
        {sessionNotes}
      </h4>
      <div>

        <form>
          {form.form_fields.map((field, index) => (
            <div key={index}>
              <label className='text-sm text-navy-700 dark:text-white'>{field.label}</label>
              <input className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                type={field.type} name={field.label} onChange={handleChange} />
            </div>
          ))}
          <div className='pt-4'>

            <button onClick={handleSubmit} type="submit" className="cursor-pointer rounded-xl bg-blue-950 px-16 py-2 font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:ml-2">
              Submit</button>
          </div>
        </form>
      </div>
      <ToastContainer />
    </Card>

  );
};

export default GuestFormPage;