import MiniStatistics from "./components/MiniStatistics";
import FakeBarChart from "assets/img/account/FakeBarChart.png";

import { MdOutlineBarChart, MdPerson, MdFileCopy } from "react-icons/md";
import ManagementTable from "./components/ManagementTable";
import { tableColumnsManagement } from "views/admin/main/account/application/variables/tableColumnsManagement";
import tableDataManagement from "views/admin/main/account/application/variables/tableDataManagement.json";

const Customers = () => {
  return (
    <div className="mt-3 h-full w-full rounded-[20px]">

      <div className="mt-3">
        <ManagementTable
          tableData={tableDataManagement}
          columnsData={tableColumnsManagement}
        />
      </div>
    </div>
  );
};

export default Customers;
