import React from "react";
import ActiveCases from "./components/ActiveCases";
import Invoices from "./components/Invoices";
import { useNavigate } from "react-router-dom";
import { casesColumns } from "./variables/casesColumns";
import { useState, useEffect } from "react";
import axios from "axiosInstance";
import Service from "./components/Service";
import { serviceColumns } from "./variables/serviceColumns";
import { todoColumns } from "./variables/todoColumns";
import Todo from "./components/Todo";
import { invoiceColumns } from "./variables/invoiceColumns";
const Dashboard = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([])
  const [equipment, setEquipment] = useState([])
  const [todo, setTodo] = useState([])
  const [cases, setCases] = useState([])
  useEffect(() => {
    const fetchData = async () => {
      const accessToken = sessionStorage.getItem("access_token");

      if (accessToken) {
        const headers = {
          "Authorization": "Token " + accessToken,
          "Accept": 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        };

        try {
          // Fetch user info
          const userResponse = await axios.get("user/", { headers });
          const casesResponse = await axios.get("case/", { headers });
          const todoResponse = await axios.get("case/task/", { headers });
          sessionStorage.setItem("fullname", userResponse.data.users[0].fullname);
          sessionStorage.setItem("company", userResponse.data.users[0].company.companyName);
          sessionStorage.setItem("role", userResponse.data.users[0].role);
          sessionStorage.setItem("abbreviation", userResponse.data.users[0].company.abbreviation);
          let storedNotifications;
          try {
            const notificationsFromStorage = sessionStorage.getItem("notifications");

            if (!notificationsFromStorage) {
              storedNotifications = [];
            } else {
              storedNotifications = JSON.parse(notificationsFromStorage);

              if (!Array.isArray(storedNotifications)) {
                storedNotifications = [];
              }
            }
          } catch (e) {
            storedNotifications = [];
            console.error("Error parsing notifications from sessionStorage:", e);
          }

          sessionStorage.setItem("notifications", storedNotifications);
          setCases(casesResponse.data.cases)
          setTodo(todoResponse.data.tasks)
          console.log(userResponse.data.users[0])



        } catch (error) {
          console.error("Error fetching data:", error);
          navigate("/platform/error/500");
        }
      } else {
        console.error("No access token found");
        navigate("/auth/login");
      }
    };

    fetchData();
  }, [navigate]);
  return (
    <div className="mt-3 flex h-full w-full flex-col gap-[20px] rounded-[20px] xl:flex-row">
      <div className="h-full w-full rounded-[20px]">
        {/* left side */}
        <div className="col-span-9 h-full w-full rounded-t-2xl xl:col-span-6">
          {/* overall & Balance 
          <div className="mb-5 grid grid-cols-6 gap-5">
            <div className="col-span-6 3xl:col-span-2">
              <ActiveBookings
                tableData={data}
                columnsData={tableColumnsMostVisited}
              />
            </div>
            
            <div className="col-span-6 3xl:col-span-2">
              <YourTransfers columnsData={equipment} />
            </div>
          </div> */}
          { /* Daily Traffic and..*/}
          <div className="mt-5 grid w-full grid-cols-1 gap-5">
            <div className="col-span-6 md:col-span-3 3xl:col-span-3">
              <ActiveCases
                tableData={cases}
                columnsData={casesColumns}
              />
            </div>
            <div className="col-span-6 md:col-span-3 3xl:col-span-3">
              <Todo
                tableData={todo}
                columnsData={todoColumns}
              />
            </div>

          </div>
          <div className="mt-5 grid w-full grid-cols-6 gap-5">
            <div className="col-span-6 md:col-span-3 3xl:col-span-3">
              <Invoices tableData={equipment} columnsData={invoiceColumns} />
            </div>
            {/*
            <div className="col-span-6 md:col-span-3 3xl:col-span-3">
              <Service
                tableData={equipment}
                columnsData={serviceColumns}
              />
            </div>
            
            <div className="col-span-6 3xl:col-span-2">
              <ProfitEstimation />
        </div>*/}
          </div>
          {/* Your Transfers & tables 
          <div className="mt-5 grid w-full grid-cols-6 gap-5">

            <div className="col-span-6 3xl:col-span-4">

              <Service
                tableData={equipment}
                columnsData={serviceColumns}
              />
            </div>
          </div>*/}
        </div>
      </div>

      {/* line */}
      <div className="flex w-0 bg-gray-200 dark:bg-navy-700 xl:w-px" />

      {/* right section 
      <div className="h-full w-full xl:w-[400px] xl:min-w-[300px] 2xl:min-w-[400px]">
        <YourCard />
      </div>*/}
    </div>
  );
};

export default Dashboard;
