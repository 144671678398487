export const contactLogsColumns = [

  {
    Header: 'Contact Title',
    accessor: 'event_name',
  },
  {
    Header: 'Date',
    accessor: 'event_date',

  },
  {
    Header: 'Type',
    accessor: 'event_type_display',
  },
  {
    Header: '',
    accessor: 'button',
  },
];
