import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction'; // needed for dayClick
import { useNavigate } from 'react-router-dom';
function EventCalendar(props) {
  const { calendarData, initialDate } = props;
  const [events, setEvents] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const formattedEvents = calendarData.flatMap(caseData => {
      const dates = [
        { title: `Filing Date - Case ID: ${caseData.id}`, date: caseData.case_filing_date },
        { title: `Start Date - Case ID: ${caseData.id}`, date: caseData.case_start_date },
        { title: `Hearing Date - Case ID: ${caseData.id}`, date: caseData.case_end_date }
      ];

      return dates
        .filter(dateInfo => dateInfo.date)
        .map(dateInfo => ({
          title: dateInfo.title,
          date: dateInfo.date,
          id: caseData.id
        }));
    });
    setEvents(formattedEvents);
  }, [calendarData]);

  return (
    <FullCalendar
      plugins={[dayGridPlugin, interactionPlugin]}
      initialView="dayGridMonth"
      contentHeight="600px"
      events={events}
      initialDate={initialDate}
      eventContent={renderEventContent}
      editable={false}
      minHeight="400px"
      height="100%"
      slotWidth={10}
      customIcons={false}
      eventClick={(event) => { navigate("/platform/cases/view", { state: { caseid: event.event.id } }) }}
    />
  );
}

function renderEventContent(eventInfo) {
  return (
    <>
      <b>{eventInfo.event.title}</b>
    </>
  );
}

export default EventCalendar;