export const tableBookings = [
  {
    Header: 'Title',
    accessor: 'title',
  },
  {
    Header: 'Type',
    accessor: 'type',

  },
  {
    Header: 'Due Date',
    accessor: 'dueDate',
  },
  {
    Header: 'Status',
    accessor: 'completed',
  },

  {
    Header: '',
    accessor: 'button',
  },
];
