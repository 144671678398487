import Default from "layouts/auth/types/Default";
import React from "react";
import PinInput from "react-pin-input";
import axios from "axiosInstance";
import QRCode from 'qrcode.react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
function VerificationDefault() {
  const [qrCode, setQrCode] = React.useState('');
  const [status, setStatus] = React.useState(false);
  const navigate = useNavigate();
  React.useEffect(() => {
    axios.get('mfa/setup/', { headers: { 'Authorization': `Token ${sessionStorage.getItem('access_token')}` } })
      .then(response => {
        console.log(response.data);
        setQrCode(response.data.qr_code);
        setStatus(response.data.status);
      });
  }, []);

  const handleComplete = (value, index) => {
    console.log(value);
    axios.post('mfa/verify/', { otp: value }, { headers: { 'Authorization': `Token ${sessionStorage.getItem('access_token')}` } }).then(response => {
      console.log(response.data);
      if (response.data.success === true) {
        toast.success(response.data.message);
        navigate('/platform/dashboard');
      }
    }).catch(error => {
      console.log(error.response.data);
      toast.error("Error: Please try again!");
    });
  }
  return (
    <Default
      maincard={
        <div className="mb-16 flex h-full w-full items-center justify-center dark:!bg-navy-900 md:mx-0 md:px-0 lg:mb-10 lg:items-start lg:justify-start">
          {/* Sign in section */}
          <div className="mt-[16vh] flex flex-col rounded-[20px] bg-white pb-16 pt-12 dark:!bg-navy-900 md:pr-6 lg:pr-0 xl:pr-24">
            <h2 className="mt-1 text-4xl font-bold text-navy-700 dark:text-white">
              2-Step Verification
            </h2>

            {!status && qrCode && <QRCode value={qrCode} />}
            <p className="mt-[15px] ml-1 text-[15px] text-gray-600">
              Enter your 2-Step Verification email code to unlock!

            </p>
            {/* verification */}
            <div className="mt-7 mb-4 flex w-full items-center justify-center">

              <PinInput
                length={6}
                initialValue=""
                onChange={(value, index) => { }}
                type="numeric"
                inputMode="number"
                style={{ marginBottom: "10px" }}
                inputStyle={{
                  borderWidth: "2px",
                  width: "92px",
                  height: "92px",
                  borderColor: "#E0E5F2",
                  borderRadius: "16px",
                  color: "#2B3674",
                  fontSize: "36px",
                }}
                inputFocusStyle={{ borderColor: "#4318FF", borderWidth: "3px" }}
                onComplete={(value, index) => { handleComplete(value, index); }}
                autoSelect={true}
                regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
              />
            </div>
            {/* button */}
            <button className="h-[50px] w-full rounded-xl bg-brand-500 text-base font-medium text-white transition duration-200 hover:bg-brand-600 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
              Unlock
            </button>
            <p className="mt-4 text-sm font-medium text-navy-700 dark:text-gray-500">
              Haven't received it?
              <span className="text-sm font-medium text-brand-500 dark:text-white">
                {" "}
                Resend a new code{" "}
              </span>
            </p>
          </div>
          <ToastContainer />
        </div>
      }
    />
  );
}

export default VerificationDefault;
