import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from "axiosInstance";
import Card from "components/card";
import { AiOutlinePlus } from "react-icons/ai";
import { IoEyeOutline } from "react-icons/io5";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InputField from 'components/fields/InputField';
const ActivationFormPage = () => {
  const { activation_id } = useParams();
  const [form, setForm] = useState(null);
  const [companyName, setCompanyName] = useState("");
  const [firstAddress, setFirstAddress] = useState("");
  const [secondAddress, setSecondAddress] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [phone, setPhone] = useState("");
  const [abreviation, setAbreviation] = useState("");
  const [first_name, setFirst_name] = useState("");
  const [lastName, setLastName] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({});
  const [sessionNotes, setSessionNotes] = useState("");
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  useEffect(() => {
    const fetchForm = async () => {
      try {
        const response = await axios.get(`activation/?activation_code=${activation_id}`);
        console.log(response.data)
        setForm(response.data.forms);
        setSessionNotes(response.data.session_notes);
      } catch (err) {
        setError(err.response?.data?.error || 'An error occurred');
      } finally {
        setLoading(false);
      }
    };

    fetchForm();
  }, [activation_id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = {
        companyName: companyName,
        first_address: firstAddress,
        second_address: secondAddress,
        city: city,
        country: country,
        postcode: postalCode,
        company_phone: phone,
        abbreviation: abreviation,
        first_name: first_name,
        last_name: lastName,
        activation_code: activation_id

      }
      await axios.post(`company/`, data);
      toast.success("Account successfully activated, you will receive an email shortly");
    } catch (err) {
      alert('Error submitting form');
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    if (error == "Session has expired") {
      return (
        <Card extra={"w-full px-9 py-3 h-full"}>

          <div className="mt-2 flex w-full items-center justify-between px-1">
            <div
              className={`mt-1 ml-1 h-2.5 font-poppins text-[26px] font-bold uppercase text-grey-950 dark:text-white`}

            >
              Case <span className="font-medium text-blue-950">Trackr</span>
            </div>
          </div>

          <div className="mt-20 flex w-full items-center justify-between px-1" >
            <h4 className="font-dm text-xl font-bold text-center text-navy-700 dark:text-white">
              This session has expired. Please contact the admin to get a new link.
            </h4>
          </div>
        </Card>
      );
    }
    return <div>{error}</div>;
  }


  return (

    <Card extra={"w-full px-9 py-3 h-full"}>

      <div className="mt-2 flex w-full items-center justify-between px-1">

        <h4 className="font-dm text-xl font-bold text-navy-700 dark:text-white">

        </h4>

        <div
          className={`mt-1 ml-1 h-2.5 font-poppins text-[26px] font-bold uppercase text-grey-950 dark:text-white`}

        >
          Case <span className="font-medium text-blue-950">Trackr</span>
        </div>

      </div>

      <div className="mt-6 grid grid-cols-2 gap-3">
        <div className="col-span-2 md:col-span-1">

          <InputField
            label="Company Name"
            placeholder="Joe Smith ISW"
            id="Name"
            type="text"
            disabled={false}
            onChange={(e) => { setCompanyName(e.target.value); }}
          />
          <InputField
            label="First Line Address"
            placeholder="12 Smith Street"
            id="Name"
            type="text"
            disabled={false}
            onChange={(e) => { setFirstAddress(e.target.value); }}
          />
          <InputField
            label="Second Line Address"
            placeholder="Small Town"
            id="Name"
            type="text"
            disabled={false}
            onChange={(e) => { setSecondAddress(e.target.value); }}
          />
          <InputField
            label="City"
            placeholder="Manchester"
            id="Name"
            type="text"
            disabled={false}
            onChange={(e) => { setCity(e.target.value); }}
          />
          <InputField
            label="Post Code"
            placeholder="M1 2GG"
            id="Name"
            type="text"
            disabled={false}
            onChange={(e) => { setPostalCode(e.target.value); }}
          />
        </div>
        {/* right side */}
        <div className="col-span-2 md:col-span-1">
          <InputField
            label="First Name"
            placeholder="Joe"
            id="Name"
            type="text"
            disabled={false}
            onChange={(e) => { setFirst_name(e.target.value); }}
          />
          <InputField
            label="Last Name"
            placeholder="Smith"
            id="Name"
            type="text"
            disabled={false}
            onChange={(e) => { setLastName(e.target.value); }}
          />
          <InputField
            label="Company Phone Number"
            placeholder="0161123456"
            id="Name"
            type="text"
            disabled={false}
            onChange={(e) => { setPhone(e.target.value); }}
          />
          <InputField
            label="Company Abbreviation"
            placeholder="JSISW"
            id="Name"
            type="text"
            disabled={false}
            onChange={(e) => { setAbreviation(e.target.value); }}
          />


        </div>

        <div className="pt-8  pb-8 flex-row flex">
          <button
            onClick={handleSubmit}
            className="cursor-pointer rounded-xl bg-blue-950 px-16 py-2 font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:ml-2"
          >
            Submit
          </button>
        </div>
      </div>
      <ToastContainer />
    </Card>

  );
};

export default ActivationFormPage;