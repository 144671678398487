export const childrenColumns = [

  {
    Header: 'Name',
    accessor: 'child_name',
  },
  {
    Header: 'DOB',
    accessor: 'child_dob',

  },

  {
    Header: '',
    accessor: 'button',
  },
];
