import Delete from "./components/Delete";
import Details from "./components/Details";
import DropeZone from "./components/DropZone";
import Information from "./components/Information";
import Socials from "./components/Socials";
import ImageGallery from "./components/ImageGallry";
import { useLocation } from 'react-router-dom';

const SettingsEquipment = () => {
  const location = useLocation();
  const { equipment } = location.state;

  return (
    <div className="mt-4 grid h-full w-full grid-cols-2 gap-5 xl:mt-3">
      {/* left side */}
      <div className="col-span-2 flex h-full w-full flex-col gap-5 xl:col-span-1">
        {/* Image Gallery */}
        <ImageGallery images={equipment.images} fallbackImage={"https://via.placeholder.com/800x600?text=No+Images+Available"} />

        <div className="col-span-2 flex h-full w-full flex-col gap-5">
          <Information equipment={equipment} />
        </div>
      </div>
      {/* right side */}
      <div className="col-span-2 flex h-full w-full flex-col gap-5 xl:col-span-1">
        <DropeZone fleetNumber={equipment.equipment.fleetNumber} />
        {/* <Socials /> */}
      </div>
      {/* full width */}
      <div className="col-span-2 flex h-full w-full flex-col gap-5">
        {/* <Details />*/}
        <Delete />
      </div>
    </div>
  );
};

export default SettingsEquipment;
