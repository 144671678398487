import Card from "components/card";
import TimelineItem from "components/dataDisplay/TimelineItem";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axiosInstance";

const TimeLine = (props) => {
  const { calendarData } = props;
  const navigate = useNavigate();

  function getDayName(dateStr, locale) {
    var date = new Date(dateStr);
    return date.toLocaleDateString(locale, { weekday: "long" });
  }

  const uploadImage = async (imageFile) => {
    const formData = new FormData();
    formData.append("equipmentProfileImage", imageFile);

    try {
      const response = await axios.post("equipment/images", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log(response.data);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    uploadImage(file);
  };

  return (
    <Card extra={"flex w-full flex-col max-w-[90vw] rounded-md font-bold px-4 py-4"}>
      {/* Header */}
      <div className="mb-[20px] flex items-center justify-between">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          Timeline
          <p className="text-base font-medium text-gray-600">
            See your event timeline:
          </p>
        </h4>
      </div>

      {/* Timeline items */}
      <div className="mt-[30px]">
        {calendarData.map((caseData) => {
          const dates = [
            { title: "Filing Date", date: caseData.case_filing_date },
            { title: "Start Date", date: caseData.case_start_date },
            { title: "End Date", date: caseData.case_end_date }
          ];

          return (
            <div key={caseData.id}>
              <h5 className="text-lg font-bold text-navy-700 dark:text-white mb-[20px]">
                Case ID: {caseData.id}
              </h5>
              {dates.map((dateInfo, index) => {
                if (dateInfo.date) {
                  const dateStr = dateInfo.date;
                  const day = getDayName(dateStr, "en-uk");
                  const dateParts = dateStr.split("-");
                  const dayPart = dateParts[2].split("T")[0];
                  const monthPart = dateParts[1];

                  return (
                    <TimelineItem
                      key={index}
                      mb="mb-[16px]"
                      title={dateInfo.title}
                      day={`${dayPart}/${monthPart}`}
                      weekday={day.substring(0, 3)}
                      hours="07:00" // Adjust this if you have a specific time for each event
                    />
                  );
                }
                return null;
              })}
            </div>
          );
        })}
      </div>
    </Card>
  );
};

export default TimeLine;