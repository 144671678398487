import React, { useEffect, useState } from 'react';
import axios from 'axiosInstance';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Card from 'components/card';

const ViewFilledTemplate = () => {
    const { filledTemplateId } = useParams();
    const [filledTemplate, setFilledTemplate] = useState(null);
    const access_token = sessionStorage.getItem("access_token");

    useEffect(() => {
        const fetchFilledTemplate = async () => {
            try {
                const headers = {
                    "Authorization": `Token ${access_token}`,
                };
                const response = await axios.get(`case/filled-templates/${filledTemplateId}/`, { headers });
                setFilledTemplate(response.data);
            } catch (error) {
                console.error("Error fetching filled template:", error);
                toast.error("Error fetching filled template");
            }
        };

        fetchFilledTemplate();
    }, [filledTemplateId]);

    const exportDocument = async (format) => {
        try {
            const headers = {
                "Authorization": `Token ${access_token}`,
            };
            const response = await axios.get(`case/export-document/${filledTemplateId}/${format}/`, { headers, responseType: 'blob' });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${filledTemplate.template_name}.${format === 'word' ? 'docx' : 'pdf'}`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error(`Error exporting document to ${format}:`, error);
            toast.error(`Error exporting document to ${format}`);
        }
    };

    return (
        <Card extra={"w-full px-9 py-3 h-full"}>
            <div className="mt-2 flex w-full items-center justify-between px-1">
                <h4 className="font-dm text-xl font-bold text-navy-700 dark:text-white">
                    View Filled Template
                </h4>
            </div>
            <div className="mt-[25px]">
                {filledTemplate ? (
                    <div>
                        <h4>{filledTemplate.template.template_name}</h4>
                        <p>Filled by: {filledTemplate.filled_by.username}</p>
                        <p>Filled at: {filledTemplate.filled_at}</p>
                        <div dangerouslySetInnerHTML={{ __html: filledTemplate.filled_data }}></div>
                        <div className="pt-8 pb-8 flex-row flex">
                            <button
                                onClick={() => exportDocument('word')}
                                className="cursor-pointer rounded-xl bg-blue-950 px-16 py-2 font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:ml-2"
                            >
                                Export to Word
                            </button>
                            <button
                                onClick={() => exportDocument('pdf')}
                                className="cursor-pointer rounded-xl bg-blue-950 px-16 py-2 font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:ml-2"
                            >
                                Export to PDF
                            </button>
                        </div>
                    </div>
                ) : (
                    <p>Loading...</p>
                )}
            </div>
            <ToastContainer />
        </Card>
    );
};

export default ViewFilledTemplate;