import MiniStatistics from "./components/MiniStatistics";
import FakeBarChart from "assets/img/account/FakeBarChart.png";
import { ToastContainer, toast } from 'react-toastify';
import { MdOutlineBarChart, MdPerson, MdFileCopy } from "react-icons/md";
import ManagementTable from "./components/ManagementTable";

import { tableEquipment } from "./variables/tableColumnsEquipment";
import { useNavigate } from "react-router-dom";
import axios from "axiosInstance";
import { useEffect, useState } from "react";

const AllSchedules = () => {
  const navigate = useNavigate();
  const [schedules, setSchedules] = useState({})
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      const accessToken = sessionStorage.getItem("access_token");

      if (accessToken) {
        const headers = {
          "Authorization": "Token " + accessToken,
          "Accept": 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        };

        try {

          // Fetch Schedules
          const scheduleResponse = await axios.get("schedule/", { headers });
          console.log(scheduleResponse.data.schedule)
          setSchedules(scheduleResponse.data.schedule);
          setIsLoading(false)
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      } else {
        console.error("No access token found");
        navigate("/auth/login");

      }
    };

    fetchData();
  }, []);
  if (isLoading) {
    return <div>Loading...</div>;
  }
  return (
    <div className="mt-3 h-full w-full rounded-[20px]">

      <div className="mt-3">
        <ManagementTable
          tableData={schedules}
          columnsData={tableEquipment}
        />
        <ToastContainer />
      </div>
    </div>
  );
};

export default AllSchedules;
