import MiniStatistics from "./components/MiniStatistics";
import FakeBarChart from "assets/img/account/FakeBarChart.png";

import { MdOutlineBarChart, MdPerson, MdFileCopy } from "react-icons/md";
import ManagementTable from "./components/ManagementTable";
import { tableCases } from "./variables/tableColumnsCases";

import axios from "axiosInstance";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
const AllDocumentTemplates = () => {
  const [templates, setTemplates] = useState({})
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      const accessToken = sessionStorage.getItem("access_token");

      if (accessToken) {
        const headers = {
          "Authorization": "Token " + accessToken,
          "Accept": 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        };

        try {

          // Fetch bookings
          const templatesResponse = await axios.get("document-templates/", { headers });
          console.log(templatesResponse)
          setTemplates(templatesResponse.data.templates);
          setIsLoading(false)
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      } else {
        console.error("No access token found");
        navigate("/auth/login");
      }
    };

    fetchData();
  }, []);
  if (isLoading) {
    return <div>Loading...</div>;
  }
  return (
    <div className="mt-3 h-full w-full rounded-[20px]">

      <div className="mt-3">
        <ManagementTable
          tableData={templates}
          columnsData={tableCases}
        />
      </div>
    </div>
  );
};

export default AllDocumentTemplates;
