import React, { useMemo, useState, useEffect } from 'react';
import axios from "axiosInstance";
import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import { createPortal } from 'react-dom';
import TaskCard from './components/TaskCard';
import { Scrollbars } from "react-custom-scrollbars-2";
import { kanbanRenderThumb, kanbanRenderTrack, kanbanRenderView } from "components/scrollbar/Scrollbar";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const ScheduleKanban = () => {
  const location = useLocation();
  const data = location.state;
  const [tasks, setTasks] = useState([]);
  const [columns, setColumns] = useState([
    {
      id: 'driverSchedule',
      title: `${data.schedule.driver_name}'s Schedule - Vehicle: ${data.schedule.vehicle_reg}`,
    },
    {
      id: 'unassignedTasks',
      title: "Unassigned Tasks",
    },
  ]);

  function padID(id) {
    return String(id).padStart(4, '0');
  }

  const accessToken = sessionStorage.getItem("access_token");
  const sensors = useSensors(useSensor(PointerSensor, { activationConstraint: { distance: 10 } }));
  const abbreviation = sessionStorage.getItem("abbreviation");

  useEffect(() => {
    fetchTasks();
  }, []);

  const TYPE_MAP = {
    1: "Delivery",
    2: "Collection",
    3: "Service",
    4: "Maintenance",
    5: "Other",
  };

  const fetchTasks = async () => {
    const headers = {
      "Authorization": "Token " + accessToken,
      "Accept": 'application/json',
      'Content-Type': 'application/json',
    };
    try {
      const response = await axios.get(`schedule/item/?id=${data.schedule.id}`, { headers });
      const { schedule } = response.data;

      const formattedTasks = await Promise.all(
        schedule.map(async (item) => {
          const bookingResponse = await axios.get(`booking/?id=${item.booking}`, { headers });
          const booking = bookingResponse.data.bookings[0];

          return {
            id: item.id,
            columnId: item.schedule === data.schedule.id ? 'driverSchedule' : 'unassignedTasks',
            title: `Booking ${abbreviation + padID(item.booking)}`,
            desc: TYPE_MAP[item.type] || "Unknown Type",
            bookingInfo: {
              equipmentNumber: booking.equipmentNumber,
              site_address: booking.site_address,
              site_postcode: booking.site_postcode,
              site_contact_name: booking.site_contact_name,
              startDate: booking.start,
              endDate: booking.end,
              startHours: booking.startHours,
            },
            schedule: item.schedule,
          };
        })
      );

      setTasks(formattedTasks);
    } catch (error) {
      console.error("Error fetching tasks:", error);
    }
  };

  const moveTaskToColumn = (taskId, columnId) => {
    let updatedTasks = tasks.map(task => {
      if (task.id === taskId) {
        return {
          ...task,
          columnId,
          status: columnId !== 'driverSchedule' ? 4 : 2,
          order: columnId !== 'driverSchedule' ? null : task.order // Set order to null if unassigned
        };
      }
      return task;
    });

    // Reassign orders only to tasks that remain in the 'Driver's Schedule'
    let driverScheduleTasks = updatedTasks
      .filter(task => task.columnId === 'driverSchedule')
      .sort((a, b) => (a.order ?? 0) - (b.order ?? 0)) // Ensure they are sorted by existing order
      .map((task, index) => ({ ...task, order: index })); // Reassign order

    // Merge back the unassigned tasks without changing them
    const unassignedTasks = updatedTasks.filter(task => task.columnId !== 'driverSchedule');
    updatedTasks = driverScheduleTasks.concat(unassignedTasks);

    setTasks(updatedTasks);
  };

  const moveTaskUp = (taskId) => {
    const index = tasks.findIndex(t => t.id === taskId && t.columnId === 'driverSchedule');
    if (index > 0) {
      const newTasks = [...tasks];
      [newTasks[index], newTasks[index - 1]] = [newTasks[index - 1], newTasks[index]];

      const driverScheduleTasks = newTasks.filter(task => task.columnId === 'driverSchedule');
      const updatedDriverScheduleTasks = driverScheduleTasks.map((task, i) => ({ ...task, order: i }));

      const finalTasks = newTasks.map(task => {
        const updatedTask = updatedDriverScheduleTasks.find(t => t.id === task.id);
        return updatedTask ? updatedTask : task;
      });

      setTasks(finalTasks);
    }
  };

  const moveTaskDown = (taskId) => {
    const index = tasks.findIndex(t => t.id === taskId && t.columnId === 'driverSchedule');
    if (index < tasks.length - 1 && index !== -1) {
      const newTasks = [...tasks];
      [newTasks[index], newTasks[index + 1]] = [newTasks[index + 1], newTasks[index]];

      const driverScheduleTasks = newTasks.filter(task => task.columnId === 'driverSchedule');
      const updatedDriverScheduleTasks = driverScheduleTasks.map((task, i) => ({ ...task, order: i }));

      const finalTasks = newTasks.map(task => {
        const updatedTask = updatedDriverScheduleTasks.find(t => t.id === task.id);
        return updatedTask ? updatedTask : task;
      });

      setTasks(finalTasks);
    }
  };

  const confirmSchedule = async () => {
    const headers = {
      "Authorization": "Token " + accessToken,
      "Content-Type": "application/json",
    };
    const payload = tasks.map(task => ({
      id: task.id,
      schedule: task.columnId === 'driverSchedule' ? data.schedule.id : null,
      order: task.order,
      status: task.status,
    }));
    try {
      await axios.post('schedule/order/update/', payload, { headers });
      console.log('Tasks updated successfully');
      toast.success('Schedule Successfully Updated!');
    } catch (error) {
      console.error('Failed to update tasks:', error);
      toast.error('Schedule Could Not Be Updated!');
    }
  };

  return (
    <div className="mt-20 flex flex-col overflow-hidden rounded-md xl:mt-3">
      <button onClick={confirmSchedule} className="mb-4 px-4 py-2 bg-blue-500 text-white rounded">Confirm Schedule</button>
      <Scrollbars
        autoHide
        renderTrackHorizontal={kanbanRenderTrack}
        renderThumbHorizontal={kanbanRenderThumb}
        renderView={kanbanRenderView}
      >
        <DndContext sensors={sensors}>
          <div className="m-auto flex gap-4">
            {columns.map((col) => (
              <SortableContext key={col.id} items={tasks.filter(task => task.columnId === col.id).map(task => task.id)}>
                <div className="column">
                  <h2>{col.title}</h2>
                  {tasks.filter(task => task.columnId === col.id).map(task => (
                    <TaskCard
                      key={task.id}
                      task={task}
                      moveTaskToDriver={() => moveTaskToColumn(task.id, 'driverSchedule')}
                      moveTaskToUnassigned={() => moveTaskToColumn(task.id, 'unassignedTasks')}
                      moveTaskUp={moveTaskUp}
                      moveTaskDown={moveTaskDown}
                    />
                  ))}
                </div>
              </SortableContext>
            ))}
          </div>
          {createPortal(
            <div></div>,
            document.body
          )}
        </DndContext>
        <ToastContainer />
      </Scrollbars>
    </div>
  );
};

export default ScheduleKanban;