import Card from "components/card";
import { MdOutlineCloudUpload } from "react-icons/md";
import axios from "axiosInstance";
import { useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
const DropZone = (fleetNumber) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const navigate = useNavigate();
  // Handle file selection via the file input or drag-and-drop
  const handleFiles = (files) => {
    const fileArray = Array.from(files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...fileArray]);

    // Create image previews
    const previews = fileArray.map((file) => URL.createObjectURL(file));
    setImagePreviews((prevPreviews) => [...prevPreviews, ...previews]);
  };

  // Handle the file input change
  const handleInputChange = (e) => {
    handleFiles(e.target.files);
  };

  // Drag-and-drop event handlers
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    handleFiles(e.dataTransfer.files);
  };

  // Simulate file input click when the text is clicked
  const handleClick = () => {
    document.getElementById('fileInput').click();
  };

  // Handle the actual file upload logic
  const handleUpload = (fleetNumber) => {
    console.log('Uploading files:', selectedFiles);
    console.log(fleetNumber)
    const accessToken = sessionStorage.getItem("access_token");
    if (!accessToken) {
      console.error('No access token found');
      return;
    }

    const formData = new FormData();
    formData.append('fleetNumber', fleetNumber); // Add fleetNumber to the form data

    // Loop through the selected files array and add each file to formData with the same key
    selectedFiles.forEach((file, index) => {
      formData.append('equipmentProfileImages', file);
    });

    const headers = {
      "Authorization": "Token " + accessToken,
      "Accept": 'application/json',
      'Content-Type': 'multipart/form-data',
    };

    axios.post("equipment/images", formData, { headers })
      .then((res) => {
        console.log('Upload successful:', res.data);
        toast.success('Image(s) successfully uploaded to the server!');

        // Delay navigation slightly so toast is visible on the target page
        setTimeout(() => {
          navigate("/platform/equipment/list");
        }, 5000);
      })
      .catch((e) => {
        console.error('Upload failed:', e);
        toast.error('An error occurred, please try again later!');
      });
  };

  return (
    <Card extra={"w-full py-[26px] px-[30px] h-auto"}>
      <p className="mt-1 text-xl font-bold text-navy-700 dark:text-white">
        Equipment Images
      </p>
      <div
        className={`mt-7 flex w-full items-center justify-center rounded-[20px] ${isDragging ? 'border-2 border-brand-500' : 'border-dashed border-gray-200'} bg-gray-100 px-[5px] dark:border-white/10 dark:bg-navy-700`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <input
          type="file"
          id="fileInput"
          multiple
          accept="image/png, image/jpeg"
          className="hidden"
          onChange={handleInputChange}
        />
        <div
          className="flex h-[205px] flex-col items-center justify-center rounded-xl"
          onClick={handleClick}
        >
          <p className="text-[80px] text-navy-700 dark:text-white">
            <MdOutlineCloudUpload />
          </p>
          <p className="text-lg font-bold text-navy-700 dark:text-white">
            Drop your image here, or
            <span className="pl-2 font-bold text-brand-500 dark:text-brand-400 cursor-pointer">
              Click to browse
            </span>
          </p>
          <p className="text-lightFourth pt-2 text-sm font-medium">
            PNG, JPG files are allowed
          </p>
        </div>
      </div>

      {/* Image Preview */}
      <div className="mt-4 grid grid-cols-3 gap-4">
        {imagePreviews.map((src, index) => (
          <div key={index} className="border rounded-xl overflow-hidden">
            <img src={src} alt={`Preview ${index + 1}`} className="w-full h-full object-cover" />
          </div>
        ))}
      </div>

      <div className="ml-auto flex flex-col items-center gap-4 md:flex-row mt-4">
        <button
          className="rounded-xl bg-brand-500 px-10 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          onClick={() => { handleUpload(fleetNumber.fleetNumber) }}
        >
          Upload
        </button>
      </div>
      <ToastContainer />
    </Card>
  );
};

export default DropZone;
