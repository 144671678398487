export const invoiceColumns = [
    {
        Header: 'Case Ref',
        accessor: 'fleetNumber',
    },
    {
        Header: 'Due Date',
        accessor: 'type',

    },
    {
        Header: 'Amount',
        accessor: 'nextService',
    },
    {
        Header: 'Status',
        accessor: 'status',
    },
    {
        Header: 'Sent Date',
        accessor: 'hoursWorked',
    },
    {
        Header: 'Resend Invoice',
        accessor: 'hoursWorked1',
    },
];
