export const formSubmissionColumns = [

  {
    Header: 'Session Name',
    accessor: 'session_name',
  },
  {
    Header: 'Submission Date',
    accessor: 'submitted_at',

  },

  {
    Header: '',
    accessor: 'button',
  },
];
