export const parentsColumns = [

  {
    Header: 'Name',
    accessor: 'parent_name',
  },
  {
    Header: 'DOB',
    accessor: 'parent_dob',

  },
  {
    Header: 'Phone',
    accessor: 'parent_phone',
  },
  {
    Header: 'Email',
    accessor: 'parent_email',
  },

  {
    Header: '',
    accessor: 'button',
  },
];
