export const tableEquipment = [
  {
    Header: 'Start Date',
    accessor: 'start',
  },
  {
    Header: 'End Date',
    accessor: 'end',

  },
  {
    Header: 'Driver',
    accessor: 'driver_name',
  },
  {
    Header: 'Vehicle',
    accessor: 'vehicle_reg',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: '',
    accessor: 'button',
  },
];
