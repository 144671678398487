export const thirdPartyColumns = [

  {
    Header: 'Name',
    accessor: 'third_party_name',
  },

  {
    Header: 'Representing',
    accessor: 'representing_display',
  },
  {
    Header: 'Phone',
    accessor: 'third_party_phone',

  },
  {
    Header: 'Email',
    accessor: 'third_party_email',
  },

  {
    Header: 'Role',
    accessor: 'third_party_role_display',
  },
  {
    Header: 'Company / Local Authority',
    accessor: 'third_party_company',
  },
  {
    Header: 'Billing',
    accessor: 'third_party_billing',
  },
  {
    Header: '',
    accessor: 'button',
  },
];
