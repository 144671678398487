import PhotoGallery from './components/PhotoGallery';
import ProductInfo from './components/ProductInfo';
import { useLocation } from "react-router-dom";
import { useEffect } from 'react';
const TodoPage = () => {
  const location = useLocation();
  const data = location.state;

  useEffect(() => {


  }, [])
  return (
    <div className="mt-3 grid h-full w-full grid-cols-12 gap-3 rounded-[20px] bg-white bg-clip-border p-[18px] shadow-3xl shadow-shadow-500 dark:shadow-none dark:!bg-navy-800">


      <div className="col-span-12 w-full xl:col-span-5">
        <ProductInfo todo={data.todo} />
      </div>
    </div>
  );
};

export default TodoPage;
