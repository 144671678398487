import React, { useState, useEffect, useRef } from 'react';
import axios from 'axiosInstance';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import InputField from 'components/fields/InputField';
import Card from 'components/card';
import DropDownNew from "components/fields/dropdownNew";
import DatePickerInput from "components/fields/DatePicker";
import { useLocation, useNavigate } from 'react-router-dom';
const ViewContactLog = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { eventid } = location.state || {};
    const [editor, setEditor] = useState(null);
    const [editor2, setEditor2] = useState(null);
    const [title, setTitle] = useState("");
    const [billableHours, setBillableHours] = useState("");
    const [date, setDate] = useState(new Date());
    const [selectedOption, setSelectedOption] = useState("");
    const [timeType, setTimeType] = useState("");
    const editorContainerRef = useRef(null);
    const editorContainerRef2 = useRef(null);
    const access_token = sessionStorage.getItem("access_token");

    useEffect(() => {
        if (editorContainerRef.current && !editor) {
            const quillEditor = new Quill(editorContainerRef.current, {
                theme: 'snow'
            });
            quillEditor.enable(false);
            setEditor(quillEditor);
        }
        if (editorContainerRef2.current && !editor2) {
            const quillEditor2 = new Quill(editorContainerRef2.current, {
                theme: 'snow'
            });
            quillEditor2.enable(false);
            setEditor2(quillEditor2);
        }

        if (access_token) {
            const headers = {
                "Authorization": "Token " + access_token,
                "Accept": 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            };
            axios.get(`case/event/?event_id=${eventid}`, { headers }).then((response) => {
                if (response.status === 200) {
                    console.log(response.data.case_events[0])
                    setTitle(response.data.case_events[0].event_name)
                    setSelectedOption(response.data.case_events[0].event_type_display)
                    setTimeType(response.data.case_events[0].event_billtype_display)
                    setBillableHours(response.data.case_events[0].event_billhours)
                    setDate(response.data.case_events[0].event_date)
                    editor.root.innerHTML = response.data.case_events[0].event_detail
                    editor2.root.innerHTML = response.data.case_events[0].event_extended_detail
                }
            }).catch((error) => {
                console.error("Error fetching data:", error);
                toast.error("Error fetching data");
            });
        } else {
            console.error("No access token found");
            navigate("/auth/login");
        }
    }, [editor, editor2]);



    return (
        <Card extra={"w-full px-9 py-3 h-full"}>
            <div className="mt-2 flex w-full items-center justify-between px-1">

            </div>
            <div className="mt-[25px]">
                <InputField
                    label="Title"
                    placeholder="Enter title"
                    id="title"
                    type="text"
                    value={title}
                    disabled={true}
                />
                <InputField
                    label="Contact Type"
                    placeholder="2"
                    id="eventDescription"
                    type="text"

                    disabled={true}
                    value={selectedOption}
                />

                <InputField
                    label="Time Type"
                    placeholder="2"
                    id="eventDescription"
                    type="text"

                    disabled={true}
                    value={timeType}
                />
                <InputField
                    label="Duration"

                    id="eventDescription"
                    type="text"

                    disabled={true}
                    value={billableHours}
                />
                <DatePickerInput
                    disabled={true}
                    label="Date"
                    placeholder={date}
                    id="date"
                    type="text"
                    onChange={(date) => { setDate(date); }}
                />
                <div className="mt-2 flex w-full items-center justify-between px-1">
                    <h4 className="font-dm  font-regular text-navy-700 dark:text-white">
                        Summary
                    </h4>
                </div>
                <div className="pt-4">

                    <div ref={editorContainerRef} id="editor" style={{ height: '300px' }}></div>
                </div>

                <div className="mt-2 flex w-full items-center justify-between px-1">
                    <h4 className="font-dm  font-regular text-navy-700 dark:text-white">
                        Extended Details
                    </h4>
                </div>
                <div className="pt-4">

                    <div ref={editorContainerRef2} id="editor" style={{ height: '300px' }}></div>
                </div>
                <div className="pt-8 pb-8 flex-row flex">


                </div>
            </div>
            <ToastContainer />
        </Card>
    );
};

export default ViewContactLog;