export const tableCases = [
  {
    Header: 'Form Name',
    accessor: 'form_name',
  },

  {
    Header: 'Created Date',
    accessor: 'form_created_date',
  },

  {
    Header: 'Status',
    accessor: 'form_status_display',
  },
  {
    Header: '',
    accessor: 'button',
  },
];
