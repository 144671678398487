import { MdOutlineCalendarToday } from "react-icons/md";
import React, { useMemo, useState } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import Card from "components/card";
import formatDate from "components/actions/ConvertDate";
import { MdOutlineCloudUpload } from "react-icons/md";
import { IoEyeOutline } from "react-icons/io5";
import { AiOutlinePlus, AiOutlineUpload } from "react-icons/ai";
import Modal from 'react-modal';
import axios from "axiosInstance";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
function padID(id) {
  return String(id).padStart(4, '0');
}

function Documents(props) {

  const { columnsData, tableData, caseID } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const abbreviation = sessionStorage.getItem("abbreviation");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const navigate = useNavigate();
  // Handle file selection via the file input or drag-and-drop
  const handleFiles = (files) => {
    const fileArray = Array.from(files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...fileArray]);

    // Create image previews with file names
    const previews = fileArray.map((file) => ({
      url: URL.createObjectURL(file),
      name: file.name
    }));
    setImagePreviews((prevPreviews) => [...prevPreviews, ...previews]);
  };

  // Handle the file input change
  const handleInputChange = (e) => {
    handleFiles(e.target.files);
  };

  // Drag-and-drop event handlers
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    handleFiles(e.dataTransfer.files);
  };

  // Simulate file input click when the text is clicked
  const handleClick = () => {
    document.getElementById('fileInput').click();
  };

  // Handle the actual file upload logic
  const handleUpload = (caseID) => {
    console.log('Uploading files:', selectedFiles);
    console.log(caseID)
    const accessToken = sessionStorage.getItem("access_token");
    if (!accessToken) {
      console.error('No access token found');
      return;
    }

    const formData = new FormData();
    formData.append('case', caseID); // Add fleetNumber to the form data

    // Loop through the selected files array and add each file to formData with the same key
    selectedFiles.forEach((file, index) => {
      formData.append('document', file);
    });

    const headers = {
      "Authorization": "Token " + accessToken,
      "Accept": 'application/json',
      'Content-Type': 'multipart/form-data',
    };

    axios.post("case/document/", formData, { headers })
      .then((res) => {
        console.log('Upload successful:', res.data);
        toast.success('Document(s) successfully uploaded to the server!');

        // Delay navigation slightly so toast is visible on the target page
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      })
      .catch((e) => {
        console.error('Upload failed:', e);
        toast.error('An error occurred, please try again later!');
      });
  };

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const openModal = () => {
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };
  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    tableInstance;
  const color1 = " text-green-500 font-medium";
  const color2 = " text-red-500 font-medium";
  const retriveDocumentURL = (doc) => {
    const accessToken = sessionStorage.getItem("access_token");
    if (!accessToken) {
      console.error('No access token found');
      return;
    }

    const headers = {
      "Authorization": "Token " + accessToken,
      "Accept": 'application/json',
      'Content-Type': 'application/json',
    };

    axios.get(`proxy-document/${doc}/`, { headers, responseType: 'blob' })
      .then((res) => {
        if (res.status === 200) {
          const url = window.URL.createObjectURL(new Blob([res.data], { type: res.headers['content-type'] }));
          window.open(url, "_blank");
        } else {
          console.log(res.data);
        }
      }).catch((e) => {
        console.log(e);
      });
  };
  return (
    <Card extra={"h-full w-full pt-3 pb-10 px-8"}>
      <div className="mt-2 flex w-full items-center justify-between px-1">
        <h4 className="mb-[22px] ml-px text-lg font-bold text-navy-700 dark:text-white">
          Documents
        </h4>
        <button onClick={openModal} className="linear flex items-center justify-center rounded-md bg-lightPrimary px-8 py-1 text-lg text-blue-950 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:!bg-navy-700 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
          <AiOutlineUpload />
        </button>
        {/* <button onClick={() => { navigate("/platform/cases/document/new") }} className="linear flex items-center justify-center rounded-md bg-lightPrimary px-8 py-1 text-lg text-blue-950 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:!bg-navy-700 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
          <AiOutlinePlus />
        </button> */}
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Add Parent"
        className="fixed inset-0 flex items-center justify-center"
        overlayClassName="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center"
      >
        <div className="w-3xl max-w-3xl rounded-[20px] px-3 pt-7 md:px-8 bg-white bg-clip-border shadow-xl">
          {/* Header */}
          {/* content */}
          {/* inputs */}
          <div className="mt-6 gap-3">
            <div
              className={`mt-7 flex w-full items-center justify-center rounded-[20px] ${isDragging ? 'border-2 border-brand-500' : 'border-dashed border-gray-200'} bg-gray-100 px-[5px] dark:border-white/10 dark:bg-navy-700`}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
            >
              <input
                type="file"
                id="fileInput"
                multiple
                accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                text/plain, application/pdf"
                className="hidden"
                onChange={handleInputChange}
              />
              <div
                className="flex h-[205px] flex-col items-center justify-center rounded-xl"
                onClick={handleClick}
              >
                <p className="text-[80px] text-navy-700 dark:text-white">
                  <MdOutlineCloudUpload />
                </p>
                <p className="text-lg font-bold text-navy-700 dark:text-white">
                  Drop your file here, or
                  <span className="pl-2 font-bold text-brand-500 dark:text-brand-400 cursor-pointer">
                    Click to browse
                  </span>
                </p>
                <p className="text-lightFourth pt-2 text-sm font-medium">
                  Only PDF & DOCX files are allowed
                </p>
              </div>
            </div>
            <div className="mt-4 grid grid-cols-3 gap-4">
              {imagePreviews.map((preview, index) => (
                <div key={index} className=" overflow-hidden">

                  <p className="w-full h-full object-cover">{preview.name}</p>
                </div>
              ))}
            </div>

            <div className="pt-8 pb-8 flex-row flex">
              <button
                onClick={closeModal}
                className="cursor-pointer rounded-xl bg-blue-950 px-16 py-2 font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:ml-2"
              >
                Close
              </button>
              <button
                onClick={() => { handleUpload(caseID) }}
                className="cursor-pointer rounded-xl bg-blue-950 px-16 py-2 font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:ml-2"
              >
                Upload
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <div className="overflow-x-scroll 2xl:overflow-x-hidden">
        <table {...getTableProps()} className="w-full">
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="px-[14px] pt-[0px]"
                    key={index}
                  >
                    <div className="text-left text-xs font-bold uppercase tracking-wide text-gray-600">
                      {column.render("Header")}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()} className="w-full">
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr className="w-full" {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {

                    let data = "";
                    if (cell.column.Header === "Document Name") {
                      var docName = String(cell.value).split(".");
                      data = (
                        <div className="flex items-center text-sm font-bold text-navy-700 dark:text-white">
                          {docName[0]}
                        </div>
                      );
                    } else if (cell.column.Header === "Uploaded Date") {
                      data = (
                        <div className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </div>
                      );
                    }
                    else if (cell.column.Header === "Type") {

                      data = (
                        <div className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </div>
                      );
                    }
                    else if (cell.column.Header === "") {
                      data = (
                        <button onClick={() => retriveDocumentURL(row.original.id)} className="max-h-fit w-full max-w-max cursor-pointer text-xl text-gray-600">
                          <IoEyeOutline />
                        </button>
                      );
                    }
                    return (
                      <td
                        {...cell.getCellProps()}
                        key={index}
                        className="font-xs px-[14px] pt-[19px] pb-[4px]"
                      >
                        {data}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <ToastContainer />
    </Card>
  );
}

export default Documents;
