import { useState } from "react";
import Stepper from "./components/Stepper";
import StepperControl from "./components/StepperControl";
import { UseContextProvider } from "./contexts/StepperContext";
import BookingInfo from "./components/steps/BookingInfo";
import MachineSelection from "./components/steps/MachineSeletion";
import Pricing from "./components/steps/Pricing";
import Card from "components/card";
import EquipmentAvailability from "./components/steps/Availability";
const NewCase = () => {
  const [currentStep, setCurrentStep] = useState(1);

  const steps = [
    { stepNo: 1, name: "Case Info" },
    { stepNo: 2, name: "Parents" },
    { stepNo: 3, name: "Children" },
    { stepNo: 4, name: "Confirmation" },
  ];

  const displayStep = (step) => {
    switch (step.stepNo) {
      case 1:
        return <MachineSelection />;
      case 2:
        return <EquipmentAvailability />;
      case 3:
        return <BookingInfo />;
      case 4:
        return <Pricing />;
      default:
    }
  };

  const handleClick = (direction) => {
    let newStep = currentStep;

    direction === "next" ? newStep++ : newStep--;
    // check if steps are within bounds
    newStep > 0 && newStep <= steps.length && setCurrentStep(newStep);
  };
  return (
    <div className="mt-3 h-full w-full">
      <div className="h-[350px] w-full rounded-[20px] bg-blue-950 md:h-[390px]" />
      <div className="w-md:5/6 mx-auto h-full w-full md:px-3  3xl:w-10/12">
        <div className="-mt-[280px] w-full pb-10 md:-mt-[240px] md:px-[70px]">
          <Stepper
            action={setCurrentStep}
            steps={steps}
            currentStep={currentStep}
          />
        </div>
        <Card extra={"h-full mx-auto pb-3"}>
          <div className="rounded-[20px]">
            <UseContextProvider>
              {displayStep(steps[currentStep - 1])}
            </UseContextProvider>
          </div>
          {/* navigation button */}
          <StepperControl
            handleClick={handleClick}
            currentStep={currentStep}
            steps={steps}
          />
        </Card>
      </div>
    </div>
  );
};

export default NewCase;
