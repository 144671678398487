import { MdOutlineCalendarToday } from "react-icons/md";
import React, { useMemo, useState } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import Card from "components/card";
import formatDate from "components/actions/ConvertDate";
import { IoEyeOutline } from "react-icons/io5";
import { AiOutlinePlus, AiOutlineUpload } from "react-icons/ai";
import DatePickerInput from "components/fields/DatePicker";
import InputField from "components/fields/InputField";
import Modal from 'react-modal';
import TextField from "components/fields/TextField";
import DropDownNew from "components/fields/dropdownNew";
import axios from "axiosInstance";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function padID(id) {
  return String(id).padStart(4, '0');
}
function Children(props) {
  const { columnsData, tableData, caseID } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const access_token = sessionStorage.getItem("access_token");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [viewModalIsOpen, setViewModelIsOpen] = useState(false);
  const [name, setName] = useState("");
  const [dob, setDOB] = useState(new Date());
  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    tableInstance;
  const color1 = " text-green-500 font-medium";
  const color2 = " text-red-500 font-medium";

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };
  const closeViewModal = () => {
    setViewModelIsOpen(false);
  };
  const selectedLog = (row) => {
    console.log(row.original);

  }
  const addChild = () => {
    console.log("Add Child");
    const data = {
      name: name,
      dob: dob,
      case: caseID

    }
    console.log(data);

    if (access_token) {
      const headers = {
        "Authorization": "Token " + access_token,
        "Accept": 'application/json',
        'Content-Type': 'application/json',
      }
      axios.post("case/child/", data, { headers: headers }).then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          toast.success('Child Successfully Added!');
          setTimeout(() => {
            closeModal();
            window.location.reload();
          }, 5000);

        }
        else {
          console.log(res.data);
        }
      }).catch((e) => {
        console.log(e);
      });

    };
  }
  return (
    <Card extra={"h-full w-full pt-3 pb-10 px-8"}>
      <div className="mt-2 flex w-full items-center justify-between px-1">
        <h4 className="mb-[22px] ml-px text-lg font-bold text-navy-700 dark:text-white">
          Children
        </h4>
        <button onClick={openModal} className="linear flex items-center justify-center rounded-md bg-lightPrimary px-8 py-1 text-lg text-blue-950 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:!bg-navy-700 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
          <AiOutlinePlus />
        </button>
      </div>

      <div className="overflow-x-scroll 2xl:overflow-x-hidden">
        <table {...getTableProps()} className="w-full">
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="px-[14px] pt-[0px]"
                    key={index}
                  >
                    <div className="text-left text-xs font-bold uppercase tracking-wide text-gray-600">
                      {column.render("Header")}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()} className="w-full">
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr className="w-full" {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {

                    let data = "";
                    if (cell.column.Header === "Name") {
                      data = (
                        <div className="flex items-center text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </div>
                      );
                    } else if (cell.column.Header === "DOB") {
                      data = (
                        <div className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </div>
                      );
                    }

                    else if (cell.column.Header === "") {
                      data = (
                        <button className="max-h-fit w-full max-w-max cursor-pointer text-xl text-gray-600">
                          <IoEyeOutline />
                        </button>
                      );
                    }
                    return (
                      <td
                        {...cell.getCellProps()}
                        key={index}
                        className="font-xs px-[14px] pt-[19px] pb-[4px]"
                      >
                        {data}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Add Parent"
        className="fixed inset-0 flex items-center justify-center"
        overlayClassName="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center"
      >
        <div className="w-3xl max-w-3xl rounded-[20px] px-3 pt-7 md:px-8 bg-white bg-clip-border shadow-xl">
          {/* Header */}


          {/* content */}
          {/* inputs */}
          <div className="mt-6 grid grid-cols-2 gap-3">
            <div className="col-span-2 md:col-span-1">

              <InputField
                label="Name"
                placeholder="Joe Doe"
                id="Name"
                type="text"
                disabled={false}
                onChange={(e) => { setName(e.target.value); }}
              />


            </div>
            {/* right side */}
            <div className="col-span-2 md:col-span-1">

              <DatePickerInput
                extra="mb-4"
                label="DOB"
                placeholder={dob}
                id="dob"
                type="text"
                onChange={(date) => { setDOB(date); }}
              />




            </div>

            <div className="pt-8  pb-8 flex-row flex">
              <button
                onClick={closeModal}
                className="cursor-pointer rounded-xl bg-blue-950 px-16 py-2 font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:ml-2"
              >
                Close
              </button>
              <button
                onClick={addChild}
                className="cursor-pointer rounded-xl bg-blue-950 px-16 py-2 font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:ml-2"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <ToastContainer />
    </Card>
  );
}

export default Children;
