import { MdOutlineCalendarToday } from "react-icons/md";
import React, { useMemo, useState } from "react";
import InputField from "components/fields/InputField";
import DatePickerInput from "components/fields/DatePicker";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import Card from "components/card";
import formatDate from "components/actions/ConvertDate";
import { IoEyeOutline } from "react-icons/io5";
import { BiTrash } from "react-icons/bi";
import { AiOutlinePlus, AiOutlineUpload } from "react-icons/ai";
import Modal from 'react-modal';
import axios from "axiosInstance";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
Modal.setAppElement('#root');
function padID(id) {
  return String(id).padStart(4, '0');
}
function Parents(props) {
  const { columnsData, tableData, caseID } = props;
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const [isLoading, setIsLoading] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [dob, setDob] = useState(new Date());
  const [parents, setParents] = useState([]);
  const [first_address, setFirstAddress] = useState("");
  const [second_address, setSecondAddress] = useState("");
  const [city, setCity] = useState("");
  const [postcode, setPostcode] = useState("");
  const [country, setCountry] = useState("");
  const access_token = sessionStorage.getItem("access_token");
  const headers = {
    "Authorization": "Token " + access_token,
    "Accept": 'application/json',
    'Content-Type': 'application/json',
  }
  const addParent = () => {
    console.log("Add Parent");
    console.log(name, email, phone, dob);

    const data = {
      name: name,
      email: email,
      phone: phone,
      dob: dob,
      first_address: first_address,
      second_address: second_address,
      city: city,
      postcode: postcode,
      country: country,
      case: caseID
    };

    const updatedParents = [...parents, data];
    axios.post("case/parent/", data, { headers: headers }).then((response) => {
      if (response.status == 200) {
        console.log(response.data);
        toast.success('Parent Added Successfully!');
        setTimeout(() => {
          closeModal();
          window.location.reload();
        }, 5000);

      }
    });
    setParents(updatedParents);
    closeModal();
  };
  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    tableInstance;
  const color1 = " text-green-500 font-medium";
  const color2 = " text-red-500 font-medium";
  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <Card extra={"h-full w-full pt-3 pb-10 px-8"}>
      <div className="mt-2 flex w-full items-center justify-between px-1">
        <h4 className="mb-[22px] ml-px text-lg font-bold text-navy-700 dark:text-white">
          Parents
        </h4>
        <button onClick={openModal} className="linear flex items-center justify-center rounded-md bg-lightPrimary px-8 py-1 text-lg text-blue-950 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:!bg-navy-700 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
          <AiOutlinePlus />
        </button>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Add Parent"
        className="fixed inset-0 flex items-center justify-center"
        overlayClassName="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center"
      >
        <div className="w-3xl max-w-3xl rounded-[20px] px-3 pt-7 md:px-8 bg-white bg-clip-border shadow-xl">
          {/* Header */}


          {/* content */}
          {/* inputs */}
          <div className="mt-6 grid grid-cols-2 gap-3">
            <div className="col-span-2 md:col-span-1">

              <InputField
                label="Name"
                placeholder="Joe Smith"
                id="lastName"
                type="text"
                disabled={false}
                onChange={(e) => { setName(e.target.value); }}
              />

              <InputField
                label="Email"
                placeholder="JoeSmith@gmail.com"
                id="email"
                type="email"
                disabled={false}
                onChange={(e) => { setEmail(e.target.value); }}
              />
              <InputField
                label="Phone Number"
                placeholder="07123654871"
                id="phone"
                type="phone"
                disabled={false}
                onChange={(e) => { setPhone(e.target.value); }}
              />
              <DatePickerInput
                extra="mb-4"
                label="DOB"
                placeholder={dob}
                id="dob"
                type="text"
                onChange={(date) => { setDob(date); }}
              />
            </div>
            {/* right side */}
            <div className="col-span-2 md:col-span-1">
              <InputField
                label="First Line Address"
                placeholder="12 Smith Street"
                id="first_address"
                type="text"
                disabled={false}
                onChange={(e) => { setFirstAddress(e.target.value); }}
              />
              <InputField
                label="Second Line Address"
                placeholder="Small Town"
                id="second_address"
                type="text"
                disabled={false}
                onChange={(e) => { setSecondAddress(e.target.value); }}
              />
              <InputField
                label="City"
                placeholder="Manchester"
                id="first_address"
                type="text"
                disabled={false}
                onChange={(e) => { setCity(e.target.value); }}
              />
              <InputField
                label="Postcode"
                placeholder="M4 2HU"
                id="postcode"
                type="text"
                disabled={false}
                onChange={(e) => { setPostcode(e.target.value); }}
              />
              <InputField
                label="Country"
                placeholder="United Kingdom"
                id="country"
                type="text"
                disabled={false}
                onChange={(e) => { setCountry(e.target.value); }}
              />
            </div>

            <div className="pt-8  pb-8 flex-row flex">
              <button
                onClick={closeModal}
                className="cursor-pointer rounded-xl bg-blue-950 px-16 py-2 font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:ml-2"
              >
                Close
              </button>
              <button
                onClick={addParent}
                className="cursor-pointer rounded-xl bg-blue-950 px-16 py-2 font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:ml-2"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <div className="overflow-x-scroll 2xl:overflow-x-hidden">
        <table {...getTableProps()} className="w-full">
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="px-[14px] pt-[0px]"
                    key={index}
                  >
                    <div className="text-left text-xs font-bold uppercase tracking-wide text-gray-600">
                      {column.render("Header")}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()} className="w-full">
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr className="w-full" {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {

                    let data = "";
                    if (cell.column.Header === "Name") {
                      data = (
                        <div className="flex items-center text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </div>
                      );
                    } else if (cell.column.Header === "DOB") {
                      data = (
                        <div className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </div>
                      );
                    }
                    else if (cell.column.Header === "Phone") {

                      data = (
                        <div className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </div>
                      );
                    }
                    else if (cell.column.Header === "Email") {

                      data = (
                        <div className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </div>
                      );
                    }
                    else if (cell.column.Header === "") {
                      data = (
                        <div>
                          <button className="max-h-fit w-full max-w-max cursor-pointer text-xl text-gray-600">
                            <IoEyeOutline />
                          </button>
                          <button className="max-h-fit w-full max-w-max cursor-pointer text-xl text-red-600 pl-[12px]">
                            <BiTrash />
                          </button>
                        </div>

                      );
                    }
                    return (
                      <td
                        {...cell.getCellProps()}
                        key={index}
                        className="font-xs px-[14px] pt-[19px] pb-[4px]"
                      >
                        {data}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <ToastContainer />
    </Card>
  );
}

export default Parents;
