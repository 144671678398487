import React, { useState } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { MdArrowUpward, MdArrowDownward } from 'react-icons/md';
import formatDate from 'components/actions/ConvertDate';

function TaskCard({ task, moveTaskToDriver, moveTaskToUnassigned, moveTaskUp, moveTaskDown }) {
  const [isEditMode, setIsEditMode] = useState(false);

  const { setNodeRef, attributes, listeners, transform, transition } = useSortable({
    id: task.id,
    disabled: isEditMode,
  });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  const toggleEditMode = () => setIsEditMode(!isEditMode);
  const TYPE_MAP = {
    1: "Delivery",
    2: "Collection",
    3: "Service",
    4: "Maintenance",
    5: "Other",
  };
  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}
      className="task flex flex-col border rounded p-4 shadow space-y-2 bg-white"
      onMouseEnter={() => setIsEditMode(true)}
      onMouseLeave={() => setIsEditMode(false)}
    >
      <div className="flex justify-between items-center">
        <h4 className="font-bold text-lg">{task.title}</h4>
        <div className="flex space-x-2 ml-4">
          {task.columnId !== 'driverSchedule' && (
            <button onClick={() => moveTaskToDriver(task.id)}
              className="p-1 rounded bg-blue-500 text-white hover:bg-blue-700 transition duration-150 ease-in-out">
              Assign to Driver
            </button>
          )}
          {task.columnId !== 'unassignedTasks' && (
            <button onClick={() => moveTaskToUnassigned(task.id)}
              className="p-1 rounded bg-green-500 text-white hover:bg-green-700 transition duration-150 ease-in-out">
              Move to Unassigned
            </button>
          )}
          <button onClick={() => moveTaskUp(task.id)} className="p-1">
            <MdArrowUpward />
          </button>
          <button onClick={() => moveTaskDown(task.id)} className="p-1">
            <MdArrowDownward />
          </button>
        </div>
      </div>

      <div>

        <p>Type: {task.desc}</p>
        <p>Equipment Number: {task.bookingInfo.equipmentNumber}</p>
        <p>Site Address: {String(task.bookingInfo.site_address).toUpperCase()}, {String(task.bookingInfo.site_postcode).toUpperCase()}</p>
        <p>Site Contact: {task.bookingInfo.site_contact_name}</p>
        <p>Hire Date: {formatDate(task.bookingInfo.startDate)}</p>
        <p>Hire Date: {formatDate(task.bookingInfo.endDate)}</p>
        <p>Start Hours: {task.bookingInfo.startHours}</p>
      </div>

    </div>
  );
}

export default TaskCard;