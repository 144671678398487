import React, { useMemo, useState } from "react";
import {
    useGlobalFilter,
    usePagination,
    useSortBy,
    useTable,
} from "react-table";
import Card from "components/card";
import { IoEyeOutline, IoPencilOutline } from "react-icons/io5";
import { AiOutlinePlus } from "react-icons/ai";
import DatePickerInput from "components/fields/DatePicker";
import InputField from "components/fields/InputField";
import Modal from 'react-modal';
import TextField from "components/fields/TextField";
import DropDownNew from "components/fields/dropdownNew";
import axios from "axiosInstance";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
Modal.setAppElement('#root');

function Events(props) {
    const { columnsData, tableData, caseID } = props;
    const columns = useMemo(() => columnsData, [columnsData]);
    const data = useMemo(() => tableData, [tableData]);
    const access_token = sessionStorage.getItem("access_token");
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [viewModalIsOpen, setViewModelIsOpen] = useState(false);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [billableHours, setBillableHours] = useState("");
    const [date, setDate] = useState(new Date());
    const [selectedOption, setSelectedOption] = useState("");
    const [timeType, setTimeType] = useState("");
    const navigate = useNavigate();
    const tableInstance = useTable(
        {
            columns,
            data,
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
        tableInstance;



    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };
    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };
    const closeViewModal = () => {
        setViewModelIsOpen(false);
    };
    const selectedLog = (row) => {
        console.log(row.original);
        setTitle(row.original.event_name);
        setDescription(row.original.event_description);
        setDate(row.original.event_date);
        setSelectedOption(row.original.event_type_display);
        setBillableHours(row.original.event_billhours);
        setTimeType(row.original.event_billtype_display);
        setViewModelIsOpen(true);
    }
    const addEvent = () => {
        console.log("Add Event");
        const data = {
            title: title,
            description: description,
            date: date,
            type: selectedOption,
            case: caseID,
            time_type: timeType,
            billable_hours: billableHours


        }
        console.log(data);

        if (access_token) {
            const headers = {
                "Authorization": "Token " + access_token,
                "Accept": 'application/json',
                'Content-Type': 'application/json',
            }
            axios.post("case/event/", data, { headers: headers }).then((res) => {
                if (res.status == 200) {
                    console.log(res.data);
                    toast.success('Contact Log Successfully Updated!');
                    setTimeout(() => {
                        closeModal();
                        window.location.reload();
                    }, 5000);

                }
                else {
                    console.log(res.data);
                }
            }).catch((e) => {
                console.log(e);
            });

        };
    }
    const options = [
        { label: "Email", value: 1 },
        { label: "Call", value: 2 },
        { label: "Meeting", value: 3 },
        { label: "Other", value: 4 },
    ];
    const billoptions = [
        { label: "LEGAL AID", value: 1 },
        { label: "BILLABLE", value: 2 },
        { label: "TRAVEL", value: 3 },
        { label: "OTHER", value: 4 },
    ];
    return (
        <Card extra={"h-full w-full pt-3 pb-10 px-8"}>
            <div className="mt-2 flex w-full items-center justify-between px-1">
                <h4 className="mb-[22px] ml-px text-lg font-bold text-navy-700 dark:text-white">
                    Contact Logs
                </h4>
                <button onClick={() => { navigate("/platform/cases/contact/new", { state: { caseid: caseID } }) }} className="linear flex items-center justify-center rounded-md bg-lightPrimary px-8 py-1 text-lg text-blue-950 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:!bg-navy-700 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                    <AiOutlinePlus />
                </button>
            </div>

            <div className="overflow-x-scroll 2xl:overflow-x-hidden">
                <table {...getTableProps()} className="w-full">
                    <thead>
                        {headerGroups.map((headerGroup, index) => (
                            <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                                {headerGroup.headers.map((column, index) => (
                                    <th
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                        className="px-[14px] pt-[0px]"
                                        key={index}
                                    >
                                        <div className="text-left text-xs font-bold uppercase tracking-wide text-gray-600">
                                            {column.render("Header")}
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>

                    <tbody {...getTableBodyProps()} className="w-full">
                        {page.map((row, index) => {
                            prepareRow(row);
                            return (
                                <tr className="w-full" {...row.getRowProps()} key={index}>
                                    {row.cells.map((cell, index) => {

                                        let data = "";
                                        if (cell.column.Header === "Contact Title") {
                                            data = (
                                                <div className="flex items-center text-sm font-bold text-navy-700 dark:text-white">
                                                    {cell.value}
                                                </div>
                                            );
                                        }
                                        else if (cell.column.Header === "Date") {

                                            data = (
                                                <div className="text-sm font-bold text-navy-700 dark:text-white">
                                                    {cell.value}
                                                </div>
                                            );
                                        }
                                        else if (cell.column.Header === "Type") {

                                            data = (
                                                <div className="text-sm font-bold text-navy-700 dark:text-white">
                                                    {cell.value}
                                                </div>
                                            );
                                        }
                                        else if (cell.column.Header === "") {
                                            data = (
                                                <div>
                                                    <button className="max-h-fit w-full max-w-max cursor-pointer text-xl text-gray-600" onClick={() => { navigate("/platform/cases/contact/edit", { state: { eventid: row.original.id } }) }}>
                                                        <IoPencilOutline />
                                                    </button>
                                                    <button className="max-h-fit w-full max-w-max cursor-pointer text-xl text-gray-600" onClick={() => { navigate("/platform/cases/contact/view", { state: { eventid: row.original.id } }) }}>
                                                        <IoEyeOutline />
                                                    </button>
                                                </div>

                                            );
                                        }
                                        return (
                                            <td
                                                {...cell.getCellProps()}
                                                key={index}
                                                className="font-xs px-[14px] pt-[19px] pb-[4px]"
                                            >
                                                {data}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Add Parent"
                className="fixed inset-0 flex items-center justify-center   self-center"
                overlayClassName="fixed inset-0   flex justify-center items-center"
            >
                <div className="w-3xl rounded-[20px] px-3 pt-7 md:px-8 bg-white ">
                    {/* Header */}

                    <div >
                        <h4 className="mb-[22px] ml-px text-lg font-bold text-navy-700 dark:text-white">Contact Log</h4>
                    </div>
                    {/* content */}
                    {/* inputs */}
                    <div className="mt-6 grid grid-cols-2 gap-3">
                        <div className="col-span-2 md:col-span-1">

                            <InputField
                                label="Title"
                                placeholder="Event Title"
                                id="title"
                                type="text"
                                disabled={false}
                                onChange={(e) => { setTitle(e.target.value); }}
                            />
                            <DropDownNew

                                label="Time Type"
                                id="timeType"
                                placeholder="Select time type"
                                options={billoptions}
                                value={timeType}
                                onChange={(event) => { setTimeType(event.target.value) }}
                            />
                            <InputField
                                label="Hours Worked"
                                placeholder="2"
                                id="eventDescription"
                                type="text"

                                disabled={false}
                                onChange={(e) => setBillableHours(e.target.value)}
                            />
                        </div>
                        {/* right side */}
                        <div className="col-span-2 md:col-span-1">

                            <DatePickerInput

                                label="Date"
                                placeholder={date}
                                id="date"
                                type="text"
                                onChange={(date) => { setDate(date); }}
                            />
                            <DropDownNew

                                label="Contact Type"
                                id="contactType"
                                placeholder="Select contact type"
                                options={options}
                                value={selectedOption}
                                onChange={handleSelectChange}
                            />


                            <TextField
                                label="Event Description"
                                placeholder="Example Event Description"
                                id="eventDescription"
                                type="text"

                                disabled={false}
                                onChange={(e) => setDescription(e.target.value)}
                            />

                        </div>

                        <div className="pt-8  pb-8 flex-row flex">
                            <button
                                onClick={closeModal}
                                className="cursor-pointer rounded-xl bg-blue-950 px-16 py-2 font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:ml-2"
                            >
                                Close
                            </button>
                            <button
                                onClick={addEvent}
                                className="cursor-pointer rounded-xl bg-blue-950 px-16 py-2 font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:ml-2"
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={viewModalIsOpen}
                onRequestClose={closeViewModal}
                contentLabel="Add Parent"
                className="fixed inset-0 flex items-center justify-center   self-center"
                overlayClassName="fixed inset-0   flex justify-center items-center"
            >
                <div className="w-3xl rounded-[20px] px-3 pt-7 md:px-8 bg-white ">
                    {/* Header */}

                    <div >
                        <h4 className="mb-[22px] ml-px text-lg font-bold text-navy-700 dark:text-white" x>View Contact Log</h4>
                    </div>
                    {/* content */}
                    {/* inputs */}
                    <div className="mt-6 grid grid-cols-2 gap-3">
                        <div className="col-span-2 md:col-span-1">

                            <InputField
                                label="Title"
                                value={title}
                                id="title"
                                type="text"
                                disabled={true}
                                readOnly
                            />

                            <InputField
                                label="Type"
                                value={selectedOption}
                                id="title"
                                type="text"
                                disabled={true}
                                readOnly
                            />
                            <InputField
                                label="Time Type"
                                value={timeType}
                                id="title"
                                type="text"
                                disabled={true}
                                readOnly
                            />
                        </div>
                        {/* right side */}
                        <div className="col-span-2 md:col-span-1">

                            <InputField
                                label="Date"
                                value={date}
                                id="title"
                                type="text"
                                disabled={true}
                                readOnly
                            />
                            <TextField
                                label="Event Description"
                                value={description}
                                id="eventDescription"
                                type="text"
                                readOnly
                                disabled={true}

                            />
                            <InputField
                                label="Hours Worked"
                                value={billableHours}
                                id="title"
                                type="text"
                                disabled={true}
                                readOnly
                            />
                        </div>

                        <div className="pt-8  pb-8 flex-row flex">
                            <button
                                onClick={closeViewModal}
                                className="cursor-pointer rounded-xl bg-blue-950 px-16 py-2 font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:ml-2"
                            >
                                Close
                            </button>

                        </div>
                    </div>
                </div>
            </Modal>
            <ToastContainer />
        </Card>
    );
}

export default Events;
