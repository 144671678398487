import React, { useState, useEffect, useRef } from 'react';
import axios from 'axiosInstance';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import InputField from 'components/fields/InputField';
import Card from 'components/card';

const TemplateEditor = () => {

    const [templateName, setTemplateName] = useState("");
    const editorContainerRef = useRef(null);
    const editorRef = useRef(null);
    const access_token = sessionStorage.getItem("access_token");

    useEffect(() => {
        if (editorContainerRef.current) {
            editorRef.current = new Quill(editorContainerRef.current, {
                theme: 'snow'
            });
        }
    }, []);

    const saveTemplate = () => {
        const content = editorRef.current.root.innerHTML;
        const headers = {
            "Authorization": "Token " + access_token,
            "Accept": 'application/json',
            'Content-Type': 'application/json',
        };

        axios.post('document-templates/', { name: templateName, content }, { headers: headers })
            .then(response => {
                toast.success('Template saved successfully!');

            })
            .catch(error => {
                console.error('Error saving template:', error);
                toast.error('Error saving template');
            });
    };

    return (
        <Card extra={"w-full px-9 py-3 h-full"}>
            <div className="mt-2 flex w-full items-center justify-between px-1">
                <h4 className="font-dm text-xl font-bold text-navy-700 dark:text-white">
                    Create Document Template
                </h4>
            </div>
            <div className="mt-[25px]">
                <InputField
                    label="Template Name"
                    placeholder="Enter template name"
                    id="templateName"
                    type="text"
                    onChange={(e) => setTemplateName(e.target.value)}
                />
                <div className="pt-4">
                    <div ref={editorContainerRef} id="editor" style={{ height: '500px' }}></div>
                </div>
                <div className="pt-8 pb-8 flex-row flex">
                    <button

                        className="cursor-pointer rounded-xl bg-blue-950 px-16 py-2 font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:ml-2"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={saveTemplate}
                        className="cursor-pointer rounded-xl bg-blue-950 px-16 py-2 font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:ml-2"
                    >
                        Save
                    </button>
                </div>
            </div>
            <ToastContainer />
        </Card>
    );
};

export default TemplateEditor;