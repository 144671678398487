import React, { useState, useEffect } from "react";
import InputField from "components/fields/InputField";
import DatePickerInput from "components/fields/DatePicker";
import { AiOutlinePlus } from "react-icons/ai";
import { columnsDataOrders } from "../../tableList/variables/columnsDataOrders";
import SearchTableOrders from "../../tableList/components/SearchTableOrders";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";

Modal.setAppElement("#root");

const EquipmentAvailability = () => {
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [MachineSelection, setMachineSelection] = useState("Exacator");
  const [isLoading, setIsLoading] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [dob, setDob] = useState(new Date());
  const [parents, setParents] = useState([]);
  const [first_address, setFirstAddress] = useState("");
  const [second_address, setSecondAddress] = useState("");
  const [city, setCity] = useState("");
  const [postcode, setPostcode] = useState("");
  const [country, setCountry] = useState("");

  useEffect(() => {
    const parentsStr = sessionStorage.getItem("parents");
    if (parentsStr) {
      try {
        const parentsArray = JSON.parse(parentsStr);
        setParents(parentsArray);
      } catch (error) {
        console.error("Error parsing JSON:", error);
        setParents([]);
      }
    } else {
      setParents([]);
    }
    setIsLoading(false);
  }, []);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const addParent = () => {
    console.log("Add Parent");
    console.log(name, email, phone, dob);

    const data = {
      name: name,
      email: email,
      phone: phone,
      dob: dob.toISOString(), // Convert Date to string
      first_address: first_address,
      second_address: second_address,
      city: city,
      postcode: postcode,
      country: country
    };

    const updatedParents = [...parents, data];
    sessionStorage.setItem("parents", JSON.stringify(updatedParents));
    setParents(updatedParents);
    closeModal();
  };

  if (isLoading) {
    return (
      <div className={`h-full w-full rounded-[20px] px-3 pt-7 md:px-8`}>
        <div className="mt-2 flex w-full items-center justify-between px-1">
          <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
            Loading
          </h4>
        </div>
      </div>
    );
  }

  return (
    <div className={`h-full w-full rounded-[20px] px-3 pt-7 md:px-8 ${modalIsOpen ? 'blur-sm' : ''}`}>
      {/* Header */}
      <div className="mt-2 flex w-full items-center justify-between px-1">
        <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
          Form Fields
        </h4>
        <button
          onClick={openModal}
          className="linear flex items-center justify-center rounded-md bg-lightPrimary px-8 py-1 text-lg text-blue-950 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:!bg-navy-700 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20"
        >
          <AiOutlinePlus />
        </button>
      </div>
      {/* Modal */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Add Parent"
        className="fixed inset-0 flex items-center justify-center"
        overlayClassName="fixed inset-0 flex justify-center items-center"
      >
        <div className="w-xl max-w-xl rounded-[20px] px-3 pt-7 md:px-8 bg-white bg-clip-border shadow-xl">
          {/* Header */}

          {/* content */}
          {/* inputs */}
          <div className="mt-6 grid grid-cols-2 gap-3">
            <div className="col-span-2 md:col-span-1">
              <InputField
                label="Name"
                placeholder="Joe Smith"
                id="lastName"
                type="text"
                disabled={false}
                onChange={(e) => { setName(e.target.value); }}
              />

              <InputField
                label="Email"
                placeholder="JoeSmith@gmail.com"
                id="email"
                type="email"
                disabled={false}
                onChange={(e) => { setEmail(e.target.value); }}
              />
              <InputField
                label="Phone Number"
                placeholder="07123654871"
                id="phone"
                type="phone"
                disabled={false}
                onChange={(e) => { setPhone(e.target.value); }}
              />
              <DatePickerInput
                extra="mb-4"
                label="DOB"
                placeholder={dob}
                id="dob"
                type="text"
                onChange={(date) => { setDob(date); }}
              />
            </div>
            {/* right side */}
            <div className="col-span-2 md:col-span-1">
              <InputField
                label="First Line Address"
                placeholder="12 Smith Street"
                id="first_address"
                type="text"
                disabled={false}
                onChange={(e) => { setFirstAddress(e.target.value); }}
              />
              <InputField
                label="Second Line Address"
                placeholder="Small Town"
                id="second_address"
                type="text"
                disabled={false}
                onChange={(e) => { setSecondAddress(e.target.value); }}
              />
              <InputField
                label="City"
                placeholder="Manchester"
                id="first_address"
                type="text"
                disabled={false}
                onChange={(e) => { setCity(e.target.value); }}
              />
              <InputField
                label="Postcode"
                placeholder="M4 2HU"
                id="postcode"
                type="text"
                disabled={false}
                onChange={(e) => { setPostcode(e.target.value); }}
              />
              <InputField
                label="Country"
                placeholder="United Kingdom"
                id="country"
                type="text"
                disabled={false}
                onChange={(e) => { setCountry(e.target.value); }}
              />
            </div>

            <div className="pt-8  pb-8 flex-row flex">
              <button
                onClick={closeModal}
                className="cursor-pointer rounded-xl bg-blue-950 px-16 py-2 font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:ml-2"
              >
                Close
              </button>
              <button
                onClick={addParent}
                className="cursor-pointer rounded-xl bg-blue-950 px-16 py-2 font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:ml-2"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </Modal>
      {/* Content */}
      <div className="mt-6 grid grid-cols-1 gap-3">
        {parents.length > 0 ? (
          <div className="col-span-2 md:col-span-1">
            <SearchTableOrders
              tableData={parents}
              columnsData={columnsDataOrders}
            />
          </div>
        ) : (
          <div className="col-span-2 md:col-span-1">
            <h4 className="pt-[5px] text-l font-bold text-navy-700 dark:text-white">
              No Parents Added, Please Add Parents.
            </h4>
          </div>
        )}
      </div>
    </div>
  );
}

export default EquipmentAvailability;