import formatDate from "components/actions/ConvertDate";
import InputField from "components/fields/InputField";
import TagsField from "components/fields/TagsField";

import { useEffect, useState } from "react";


const Pricing = () => {
  const [caseInformation, setCaseInformation] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const case_data = sessionStorage.getItem("caseInformation")
    const data = JSON.parse(sessionStorage.getItem("caseInformation"))

    setCaseInformation(data);
    console.log("CASE", data)
    setIsLoading(false);

  }, []);

  if (isLoading) {
    return (
      <div className={`h-full w-full rounded-[20px] px-3 pt-7 md:px-8`}>
        <div className="mt-2 flex w-full items-center justify-between px-1">
          <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
            Loading
          </h4>
        </div>
      </div>
    );
  }

  return (
    <div className="h-full w-full rounded-[20px] px-3 pt-7 md:px-8">
      <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
        Case Information
      </h4>
      <div className="mt-6 grid grid-cols-2 gap-3">
        <div className="col-span-2 md:col-span-1">
          <InputField
            label="Case Name"
            id="caseName"
            type="text"
            placeholder={caseInformation.case_name}
            disabled={true}
          />
          <InputField
            label="Family Name"
            id="familyName"
            type="text"
            placeholder={caseInformation.family_name}
            disabled={true}
          />
          <InputField
            label="Start Date"
            id="familyName"
            type="text"
            placeholder={formatDate(caseInformation.start)}
            disabled={true}
          />
        </div>
        <div className="col-span-2 md:col-span-1">
          <InputField
            label="Case Description"
            id="caseDescription"
            type="text"
            placeholder={caseInformation.case_desc}
            disabled={true}

          />
          <InputField
            label="Local Authority"
            id="localAuthority"
            type="text"
            placeholder={caseInformation.local_authority}
            disabled={true}
          />
          <InputField
            label="Expected End Date"
            id="familyName"
            type="text"
            placeholder={formatDate(caseInformation.end)}
            disabled={true}
          />
        </div>
      </div>
    </div>
  );
};

export default Pricing;
