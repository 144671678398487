import { MdOutlineCalendarToday } from "react-icons/md";
import React, { useMemo } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import Card from "components/card";
import formatDate from "components/actions/ConvertDate";
import { AiOutlineCheck, } from "react-icons/ai";
import { BsArrowRight } from "react-icons/bs";
import axios from "axiosInstance";
import { useNavigate } from "react-router-dom";
function Todo(props) {
  const { columnsData, tableData } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const navigate = useNavigate();
  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    tableInstance;
  const color1 = " text-green-500 font-medium";
  const color2 = " text-red-500 font-medium";
  const completeTask = (actionURL) => {
    const accessToken = sessionStorage.getItem("access_token");

    if (accessToken) {
      const headers = {
        "Authorization": "Token " + accessToken,
        "Accept": 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      };
      const completeTaskResponse = axios.patch(actionURL, { headers }).then((res) => {
        if (res.status == 200) {
          window.location.reload();
        }
      });
    }
  }
  return (
    <Card extra={"h-full w-full pt-3 pb-10 px-8"}>
      <div className="flex items-center justify-between">
        <p className="text-lg font-bold text-navy-700 dark:text-white">
          Task List
        </p>

      </div>

      <div className="overflow-x-scroll 2xl:overflow-x-hidden">
        <table {...getTableProps()} className="w-full">
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="px-[14px] pt-[29px]"
                    key={index}
                  >
                    <div className="text-left text-xs font-bold uppercase tracking-wide text-gray-600">
                      {column.render("Header")}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()} className="w-full">
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr className="w-full" {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {

                    let data = "";
                    if (cell.column.Header === "Title") {
                      data = (
                        <div className="flex items-center text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </div>
                      );
                    } else if (cell.column.Header === "Description") {
                      data = (
                        <div className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </div>
                      );
                    }

                    else if (cell.column.Header === "Type") {
                      data = (
                        <div className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </div>
                      );
                    }
                    else if (cell.column.Header === "Priority") {
                      if (cell.value == 1) {
                        cell.value = "HIGH"
                      }
                      else if (cell.value == 2) {
                        cell.value = "MEDIUM"
                      }
                      else if (cell.value == 3) {
                        cell.value = "LOW"
                      }

                      data = (
                        <div className={`text-sm font-bold text-navy-700 dark:text-white pl-0 ${cell.value === "HIGH" ? "text-red-500" :
                          cell.value === "MEDIUM" ? 'text-orange-500' : cell.value === "MEDIUM" ? "text-green-500" : ""}`}>
                          {cell.value}
                        </div>
                      );
                    } else if (cell.column.Header === "Due Date") {
                      const date = formatDate(cell.value)
                      data = (
                        <div
                          className={`text-sm font-bold pl-0 `}
                        >
                          {date
                          }
                        </div>
                      );
                    }
                    else if (cell.column.Header === "Action") {

                      data = (
                        <button
                          onClick={() => { completeTask(cell.value) }}
                          className="linear flex items-center justify-center rounded-md bg-lightPrimary px-8 py-1 text-lg text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:!bg-navy-700 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20"
                        >
                          <AiOutlineCheck />
                        </button>
                      );
                    }
                    return (
                      <td
                        {...cell.getCellProps()}
                        key={index}
                        className="font-xs px-[14px] pt-[19px] pb-[4px]"
                      >
                        {data}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="mb-auto" />
      <div className="flex w-full items-center justify-end gap-1 hover:cursor-pointer">
        <div onClick={() => { navigate("/platform/todo/list") }} className="text-sm font-bold text-blue-950 transition-all hover:-translate-x-1 hover:cursor-pointer dark:text-white">
          View Task List
        </div>
        <div className="text-xl font-bold text-blue-950 transition-all hover:translate-x-1 hover:cursor-pointer dark:text-white">
          <BsArrowRight />
        </div>
      </div>
    </Card>
  );
}

export default Todo;
