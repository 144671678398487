import React, { useState, useEffect, useRef } from 'react';
import axios from 'axiosInstance';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import InputField from 'components/fields/InputField';
import Card from 'components/card';
import DropDownNew from "components/fields/dropdownNew";
import DatePickerInput from "components/fields/DatePicker";
import { useLocation } from 'react-router-dom';
import { set } from 'date-fns';
const EditContactLog = () => {
    const location = useLocation();
    const { eventid } = location.state || {};
    const [templateId, setTemplateId] = useState("");
    const [editor, setEditor] = useState(null);
    const [editor2, setEditor2] = useState(null);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [billableHours, setBillableHours] = useState("");
    const [date, setDate] = useState(new Date());
    const [selectedOption, setSelectedOption] = useState("");
    const [timeType, setTimeType] = useState("");
    const editorContainerRef = useRef(null);
    const editorContainerRef2 = useRef(null);
    const access_token = sessionStorage.getItem("access_token");
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        if (editorContainerRef.current && !editor) {
            const quillEditor = new Quill(editorContainerRef.current, {
                theme: 'snow'
            });
            setEditor(quillEditor);
        }
        if (editorContainerRef2.current && !editor2) {
            const quillEditor2 = new Quill(editorContainerRef2.current, {
                theme: 'snow'
            });
            setEditor2(quillEditor2);
        }
        fetchEventContent();
    }, [editor, editor2]);
    const options = [
        { label: "Assessment Session", value: 1 },
        { label: "Meeting", value: 2 },
        { label: "Family Time Observation", value: 3 },
        { label: "Direct Work / Visit To Child", value: 4 },
        { label: "Case Screen / Bundle Read", value: 5 },
        { label: "Report Filed", value: 6 },
        { label: "Email", value: 7 },
        { label: "Other", value: 8 },
    ];
    const billoptions = [
        { label: "LEGAL AID", value: 1 },
        { label: "PRIVATE RATE", value: 2 },
    ];
    const fetchEventContent = async () => {
        try {
            const headers = {
                "Authorization": `Token ${access_token}`,
            };
            const response = await axios.get(`/case/event/?event_id=${eventid}`, { headers });
            const templateContent = response.data.case_events[0];
            console.log(templateContent);
            editor.root.innerHTML = templateContent.event_detail;
            editor2.root.innerHTML = templateContent.event_extended_detail;
            setTitle(templateContent.event_name);
            setDate(templateContent.event_date);
            setSelectedOption(templateContent.event_type);
            setTimeType(templateContent.event_billtype);
            setBillableHours(templateContent.event_billhours);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching template content:", error);
            toast.error("Error fetching template content");
        }
    };


    const submitContactLog = async () => {
        try {
            const event_detail = editor.root.innerHTML;
            const event_extended_detail = editor2.root.innerHTML;
            const data = {
                event_detail: event_detail,
                event_extended_detail: event_extended_detail,
                title: title,
                date: date,
                type: selectedOption,
                time_type: timeType,
                billable_hours: billableHours,
                event_id: eventid


            };
            const headers = {
                "Authorization": `Token ${access_token}`,
                "Content-Type": "application/json",
            };

            await axios.put('case/event/', data, { headers });
            toast.success('Contact log filled and saved successfully!');
            // Add your own logic here if needed, e.g., navigation
        } catch (error) {
            console.error('Error submitting filled template:', error);
            toast.error('Error submitting contact log');
        }
    };
    const test = () => {
        console.log(editor.root.innerHTML);
        console.log(editor2.root.innerHTML);
    }

    return (
        <Card extra={"w-full px-9 py-3 h-full"}>
            <div className="mt-2 flex w-full items-center justify-between px-1">

            </div>
            <div className="mt-[25px]">
                <InputField
                    label="Title"
                    value={title}
                    id="title"
                    type="text"
                    onChange={(e) => setTitle(e.target.value)}
                />
                <DropDownNew

                    label="Contact Type"
                    id="contactType"
                    placeholder="Select contact type"
                    options={options}
                    value={selectedOption}
                    onChange={(event) => { setSelectedOption(event.target.value) }}
                />

                <DropDownNew

                    label="Time Type"
                    id="timeType"
                    placeholder="Select time type"
                    options={billoptions}
                    value={timeType}
                    onChange={(event) => { setTimeType(event.target.value) }}
                />
                <InputField
                    label="Duration"
                    value={billableHours}
                    id="eventDescription"
                    type="text"

                    disabled={false}
                    onChange={(e) => setBillableHours(e.target.value)}
                />
                <DatePickerInput

                    label="Date"
                    placeholder={date}
                    id="date"
                    type="text"
                    onChange={(date) => { setDate(date); }}
                />
                <div className="mt-2 flex w-full items-center justify-between px-1">
                    <h4 className="font-dm  font-regular text-navy-700 dark:text-white">
                        Summary
                    </h4>
                </div>
                <div className="pt-4">

                    <div ref={editorContainerRef} id="editor" style={{ height: '300px' }}></div>
                </div>

                <div className="mt-2 flex w-full items-center justify-between px-1">
                    <h4 className="font-dm  font-regular text-navy-700 dark:text-white">
                        Extended Details
                    </h4>
                </div>
                <div className="pt-4">

                    <div ref={editorContainerRef2} id="editor" style={{ height: '300px' }}></div>
                </div>
                <div className="pt-8 pb-8 flex-row flex">
                    <button
                        onClick={() => console.log('Cancel clicked')} // Replace with your own logic
                        className="cursor-pointer rounded-xl bg-blue-950 px-16 py-2 font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:ml-2"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={submitContactLog}
                        className="cursor-pointer rounded-xl bg-blue-950 px-16 py-2 font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:ml-2"
                    >
                        Submit
                    </button>
                </div>
            </div>
            <ToastContainer />
        </Card>
    );
};

export default EditContactLog;