
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
// Assets
import CardMenu from "components/card/CardMenu";
import { AiOutlinePlus } from "react-icons/ai";
import { IoEyeOutline } from "react-icons/io5";
import Card from "components/card";
import formatDate from "components/actions/ConvertDate";
import { useNavigate } from "react-router-dom";
import React, { useState, useMemo } from "react";
import InputField from "components/fields/InputField";
import axios from "axiosInstance";
import Modal from 'react-modal';
import { useForm, useFieldArray } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
Modal.setAppElement('#root');
function padID(id) {
  return String(id).padStart(4, '0');
}
const ManagementTable = (props) => {
  const { columnsData, tableData } = props;
  const navigate = useNavigate();
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const abbreviation = sessionStorage.getItem("abbreviation");
  const access_token = sessionStorage.getItem("access_token");
  const [formName, setFormName] = useState("");
  const color1 = " text-green-500 font-medium";
  const color2 = " text-red-500 font-medium";
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { control, register, handleSubmit, setValue } = useForm({
    defaultValues: {
      fields: [{ label: "", type: "text" }]
    }
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "fields"
  });

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const onSubmit = async (data) => {
    const headers = {
      "Authorization": "Token " + access_token,
      "Accept": 'application/json',
      'Content-Type': 'application/json',
    }
    try {
      const formData = {
        form_name: formName,
        form_fields: data.fields
      };
      await axios.post('case/forms/', formData, { headers: headers }).then((response) => {
        toast.success('Form Successfully Created!');
        setTimeout(() => {
          closeModal();
          window.location.reload();
        }, 5000);
      })


    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    tableInstance;

  return (
    <Card extra={"w-full px-9 py-3 h-full"}>
      <div className="mt-2 flex w-full items-center justify-between px-1">
        <h4 className="font-dm text-xl font-bold text-navy-700 dark:text-white">
          All Forms
        </h4>
        <button
          onClick={openModal}
          className="linear flex items-center justify-center rounded-md bg-lightPrimary px-8 py-1 text-lg text-blue-950 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:!bg-navy-700 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20"

        >
          <AiOutlinePlus />
        </button>
      </div>
      {/* tables */}
      <div className="mt-[25px] overflow-x-scroll 2xl:overflow-x-hidden">
        <table {...getTableProps()} className="w-full font-dm text-navy-700">
          <thead className="w-full">
            {headerGroups.map((headerGroup, index) => (
              <tr
                className="items-center border-b border-gray-200 text-base dark:border-white/10"
                {...headerGroup.getHeaderGroupProps()}
                key={index}
              >
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className=" pb-[15px] pl-[5px] text-start text-sm font-bold uppercase tracking-wide text-gray-600"
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()} className="w-full">
            {page.map((row, index) => {
              prepareRow(row);

              return (

                <tr {...row.getRowProps()} key={index} className="items-center">
                  {row.cells.map((cell, index) => {
                    let data = "";
                    if (cell.column.Header === "Form Name") {
                      data = (
                        <div className="flex items-center text-sm font-bold text-navy-700 dark:text-white">

                          <div className="flex w-full flex-col">
                            <p className="text-sm font-bold text-navy-700 dark:text-white">
                              {cell.value}
                            </p>

                          </div>
                        </div>
                      );
                    } else if (cell.column.Header === "Created Date") {
                      data = (
                        <div className="w-full text-sm font-bold dark:text-white sm:min-w-[120px] md:min-w-[150px] lg:min-w-[unset]">
                          {cell.value}
                        </div>
                      );
                    } else if (cell.column.Header === "Status") {

                      data = (
                        <div className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </div>
                      );
                    }
                    else if (cell.column.Header === "") {
                      data = (
                        <button className="max-h-fit w-full max-w-max cursor-pointer text-xl text-gray-600" onClick={() => { navigate("/platform/cases/view", { state: { caseid: row.original.id } }) }}>
                          <IoEyeOutline />
                        </button>
                      );
                    }
                    return (
                      <td
                        className="mt-[20px] py-[14px] pl-2 lg:pr-2"
                        {...cell.getCellProps()}
                        key={index}
                      >
                        {data}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Create Custom Form"
        className="fixed inset-0 flex items-center justify-center"
        overlayClassName="fixed inset-0 flex justify-center items-center"
      >
        <div className="w-xl max-w-xl rounded-[20px] px-3 pt-7 md:px-8 bg-white bg-clip-border shadow-xl">
          <form onSubmit={handleSubmit(onSubmit)}>
            <InputField
              label="Form Name"
              placeholder="Enter form name"
              id="form_name"
              type="text"
              onChange={(e) => { setFormName(e.target.value) }}
            />
            {fields.map((field, index) => (
              <div key={field.id} className="mt-6 grid grid-cols-2 gap-3">
                <div className="col-span-2 md:col-span-1">
                  <label htmlFor={`fields[${index}].label`}>Field {index + 1}</label>
                  <input
                    placeholder="Enter field label"
                    id={`fields[${index}].label`}
                    className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                    type="text"
                    {...register(`fields[${index}].label`, {
                      onChange: (e) => setValue(`fields[${index}].label`, e.target.value)
                    })}
                  />
                  <select
                    className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                    {...register(`fields[${index}].type`, {
                      onChange: (e) => setValue(`fields[${index}].type`, e.target.value)
                    })}
                  >
                    <option value="text">Text</option>
                    <option value="number">Number</option>
                    <option value="date">Date</option>
                  </select>
                </div>
                <button type="button" onClick={() => remove(index)}>Remove</button>
              </div>
            ))}
            <div className="pt-4">
              <button className="cursor-pointer rounded-xl bg-blue-950 px-16 py-2 font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:ml-2"
                type="button" onClick={() => append({ label: "", type: "text" })}>
                Add Field
              </button>
            </div>

            <div className="pt-8  pb-8 flex-row flex">
              <button
                onClick={closeModal}
                className="cursor-pointer rounded-xl bg-blue-950 px-16 py-2 font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:ml-2"
              >
                Close
              </button>
              <button
                type="submit"
                className="cursor-pointer rounded-xl bg-blue-950 px-16 py-2 font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:ml-2"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </Modal>
      <ToastContainer />
    </Card>
  );
};

export default ManagementTable;
