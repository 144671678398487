import React, { useState, useEffect, Children } from "react";
import InputField from "components/fields/InputField";
import DatePickerInput from "components/fields/DatePicker";
import { AiOutlinePlus } from "react-icons/ai";
import { columnsDataOrders } from '../../tableList/variables/columnsDataOrders';
import SearchTableOrders from '../../tableList/components/SearchTableOrders';
import axios from "axiosInstance";
import { useNavigate } from "react-router-dom";
import Modal from 'react-modal';
import ChildrenTable from "../../tableList/components/ChildrenTable";
import { childrenTableColumns } from "../../tableList/variables/childrenTableColumns";


Modal.setAppElement('#root');

const ChildrenList = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dob, setDob] = useState(new Date());
  const [children, setChildren] = useState([]);






  useEffect(() => {
    const childrenStr = sessionStorage.getItem("children");
    if (childrenStr) {
      try {
        const childrenArray = JSON.parse(childrenStr);
        setChildren(childrenArray);
      } catch (error) {
        console.error("Error parsing JSON:", error);
        setChildren([]);
      }
    } else {
      setChildren([]);
    }
    setIsLoading(false);
  }, []);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const addChild = () => {
    console.log("Add Child");
    console.log(firstName, lastName, dob);

    const data = {
      name: firstName,

      dob: dob,
    };

    const updatedChildren = [...children, data];
    sessionStorage.setItem("children", JSON.stringify(updatedChildren));
    setChildren(updatedChildren);
    closeModal();
  };

  if (isLoading) {
    return (
      <div className={`h-full w-full rounded-[20px] px-3 pt-7 md:px-8`}>
        <div className="mt-2 flex w-full items-center justify-between px-1">
          <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
            Loading
          </h4>
        </div>
      </div>
    );
  }

  return (
    <div className={`h-full w-full rounded-[20px] px-3 pt-7 md:px-8 ${modalIsOpen ? 'blur-sm' : ''}`}>
      {/* Header */}
      <div className="mt-2 flex w-full items-center justify-between px-1">
        <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
          Children
        </h4>
        <button
          onClick={openModal}
          className="linear flex items-center justify-center rounded-md bg-lightPrimary px-8 py-1 text-lg text-blue-950 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:!bg-navy-700 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20"
        >
          <AiOutlinePlus />
        </button>
      </div>
      {/* Modal */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Add Parent"
        className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 self-center"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
      >
        <div className="w-3xl rounded-[20px] px-3 pt-7 md:px-8 bg-background-100">
          {/* Header */}


          {/* content */}
          {/* inputs */}
          <div className="mt-6 grid grid-cols-2 gap-3">
            <div className="col-span-2 md:col-span-1">

              <InputField
                label="Full Name"
                placeholder="Joe Smith"
                id="firstName"
                type="text"
                disabled={false}
                onChange={(e) => { setFirstName(e.target.value); }}
              />




            </div>
            {/* right side */}
            <div className="col-span-2 md:col-span-1">
              <DatePickerInput
                extra="mb-4"
                label="DOB"
                placeholder={dob}
                id="dob"
                type="text"
                onChange={(date) => { setDob(date.toISOString()); }}
              />
            </div>

            <div className="pt-8  pb-8 flex-row flex">
              <button
                onClick={closeModal}
                className="cursor-pointer rounded-xl bg-blue-950 px-16 py-2 font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:ml-2"
              >
                Close
              </button>
              <button
                onClick={addChild}
                className="cursor-pointer rounded-xl bg-blue-950 px-16 py-2 font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:ml-2"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </Modal>
      {/* Content */}
      <div className="mt-6 grid grid-cols-1 gap-3">
        {children.length > 0 ? (
          <div className="col-span-2 md:col-span-1">
            <ChildrenTable
              tableData={children}
              columnsData={childrenTableColumns}
            />
          </div>
        ) : (
          <div className="col-span-2 md:col-span-1">
            <h4 className="pt-[5px] text-l font-bold text-navy-700 dark:text-white">
              No Children Added, Please Add Child.
            </h4>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChildrenList;