export const tableCases = [
  {
    Header: 'Template Name',
    accessor: 'template_name',
  },

  {
    Header: 'Created Date',
    accessor: 'template_created_date',
  },

  {
    Header: 'Status',
    accessor: 'template_status_display',
  },
  {
    Header: '',
    accessor: 'button',
  },
];
