import { MdStar, MdStarHalf } from "react-icons/md";
import Accordian from "./Accordian";
import { useNavigate } from "react-router-dom";
const ProductInfo = (equipment) => {
  const navigate = useNavigate();
  const data = equipment

  return (
    <div className="h-full w-full rounded-xl bg-white px-2 py-3 dark:!bg-navy-800 xl:!pl-10 xl:!pr-4">
      <h1 className="pt-[10px] text-[28px] font-semibold text-navy-700 dark:text-white md:text-[30px]">
        {equipment.equipment.fleetNumber}
      </h1>

      <p className="mt-[28px] text-[18px] text-navy-700  dark:text-white">
        Equipment Information:<br />
        Manufacturer: {equipment.equipment.manufacturer} <br />
        Model: {equipment.equipment.model}<br />
        Machine Category: {String(equipment.equipment.type).toUpperCase()}<br />
        Fuel Type: {equipment.equipment.fuelType}<br />
        Day Rate: £{equipment.equipment.dayRate}<br />
        Hours Worked: {equipment.equipment.hoursWorked}<br />
        Last Service: {equipment.equipment.lastService}<br />
        Next Service: {equipment.equipment.nextService}<br />
      </p>

      <div className="mt-[30px] lg:mt-[38px]">

        <div className="flex items-center gap-2">

          <div className={`flex w-[170px] items-center justify-center rounded-lg  py-2 text-s font-bold  dark:bg-green-50
          ${equipment.equipment.status === 1 ? 'bg-green-100 text-green-500' :
              equipment.equipment.status === 2 ? 'bg-red-100 text-red-500' :
                equipment.equipment.status === 3 ? 'bg-yellow-100 text-yellow-500' : ''}`}>
            {equipment.equipment.status === 1 ? "In Stock" :
              equipment.equipment.status === 2 ? "Booked" :
                equipment.equipment.status === 3 ? "Maintenance/Service" : null}
          </div>
        </div>
      </div>
      {/* select 
      <div className="mt-[42px] grid w-full grid-cols-1 gap-4 lg:grid-cols-2 3xl:w-5/6">
        <div className="w-full">
          <div>
            <label
              htmlFor="color"
              className="ml-3 mb-2 text-sm font-bold text-navy-700 dark:text-white"
            >
              Color
            </label>
            <div className="w-full rounded-xl border border-gray-200 py-3 pr-3 pl-2.5 text-sm font-normal text-gray-600 outline-none dark:!border-white/10 dark:!bg-navy-800 dark:text-white sm:w-full md:w-full">
              <select
                className="text-lightFourth w-full rounded-xl pr-4 text-sm outline-none dark:!bg-navy-800 dark:text-white"
                name=""
                id="color"
              >
                <option value="usd">Dark Grey</option>
                <option value="usd">Black</option>
                <option value="usd">White</option>
              </select>
            </div>
          </div>
        </div>
        <div className="w-full">
          <div>
            <label
              htmlFor="quantity"
              className="ml-3 mb-2 text-sm font-bold text-navy-700 dark:text-white"
            >
              Quantity
            </label>
            <div className="w-full rounded-xl border border-gray-200 py-3 pr-3 pl-2.5 text-sm font-normal text-gray-600 outline-none dark:!border-white/10 sm:w-full md:w-full">
              <select
                className="text-lightFourth w-full rounded-xl pr-4 text-sm outline-none dark:!bg-navy-800 dark:text-white"
                name=""
                id="quantity"
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
              </select>
            </div>
          </div>
        </div>
        
      </div>*/}
      {/* button */}
      <button onClick={() => {
        navigate("/platform/equipment/edit", { state: { equipment: equipment } })

      }} className="mt-[34px] flex w-full items-center justify-center rounded-xl bg-brand-500 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 3xl:w-1/6">
        Update Information
      </button>
      {/* accordians 
      <div>
        <Accordian />
      </div>*/}
    </div>
  );
};

export default ProductInfo;
