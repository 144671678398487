export const casesColumns = [

  {
    Header: 'Case Ref',
    accessor: 'id',
  },
  {
    Header: 'Start Date',
    accessor: 'case_start_date',

  },
  {
    Header: 'Filing Date',
    accessor: 'case_filing_date',

  },
  {
    Header: 'Hearing Date',
    accessor: 'case_end_date',

  },
  {
    Header: 'Local Authority',
    accessor: 'case_local_authority',

  },
  {
    Header: 'Assessment Type',
    accessor: 'assessment_display',

  },
  {
    Header: 'Family Name',
    accessor: 'case_family_name',
  },
  {
    Header: 'Outstanding Tasks',
    accessor: 'case_tasks',
  },
];
