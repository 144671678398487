export const childrenTableColumns = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Date of Birth',
    accessor: 'dob',
  },
  {
    Header: 'ACTIONS',
    accessor: 'actions',
  }

];
