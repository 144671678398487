import { useMemo, useState, useEffect } from "react";
import axios from "axiosInstance";
import { IoMdAdd } from "react-icons/io";
import ColumnContainer from "./components/ColumnContainer";
import { Scrollbars } from "react-custom-scrollbars-2";
import {
  kanbanRenderThumb,
  kanbanRenderTrack,
  kanbanRenderView,
} from "components/scrollbar/Scrollbar";
import {
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay,
  rectIntersection,
} from "@dnd-kit/core";
import { SortableContext } from "@dnd-kit/sortable";
import { createPortal } from "react-dom";
import TaskCard from "./components/TaskCard";

// Static column IDs
const DRIVER_SCHEDULE = "driverSchedule";
const UNASSIGNED_TASKS = "unassignedTasks";
const DRIVER_SCHEDULE_ID = 1; // The static ID used for the driver's schedule

// Default column setup
const defaultColumns = [
  {
    id: DRIVER_SCHEDULE,
    title: "Driver Schedule",
  },
  {
    id: UNASSIGNED_TASKS,
    title: "Unassigned Tasks",
  },
];

function KanbanBoard() {
  const [columns, setColumns] = useState(defaultColumns);
  const columnsId = useMemo(() => columns.map((col) => col.id), [columns]);

  const [tasks, setTasks] = useState([]);
  const [activeTask, setActiveTask] = useState(null);
  const accessToken = sessionStorage.getItem("access_token");

  // Configure DnD sensors
  const sensors = useSensors(
    useSensor(PointerSensor, { activationConstraint: { distance: 10 } })
  );

  useEffect(() => {
    fetchTasksFromBackend();
  }, []);

  // Fetch tasks from backend
  const fetchTasksFromBackend = async () => {
    if (accessToken) {
      const headers = {
        "Authorization": "Token " + accessToken,
        "Accept": 'application/json',
        'Content-Type': 'application/json',
      };

      try {
        const response = await axios.get("schedule/item/", { headers });
        const { schedule } = response.data;

        // Assign tasks to the correct columns
        const formattedTasks = schedule.map((item) => ({
          id: item.id,
          columnId: item.schedule === DRIVER_SCHEDULE_ID ? DRIVER_SCHEDULE : UNASSIGNED_TASKS,
          title: `Booking ${item.booking}`,
          desc: `Type ${item.type}`,
          schedule: item.schedule,
        }));

        setTasks(formattedTasks);
      } catch (error) {
        console.error("Error fetching tasks:", error);
      }
    }
  };

  // Update the schedule on the backend
  const updateTaskSchedule = async (taskId, newScheduleId) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        "Authorization": "Token " + accessToken,
      };

      await axios.patch(
        `schedule/item/update/${taskId}/`,
        { schedule: newScheduleId },
        { headers }
      );
    } catch (error) {
      console.error("Error updating task schedule:", error);
    }
  };

  const onDragStart = (event) => {
    if (event.active.data.current?.type === "Task") {
      setActiveTask(event.active.data.current.task);
    }
  };

  const onDragEnd = (event) => {
    setActiveTask(null);

    const { active, over } = event;
    if (!over) return;

    const activeId = active.id;
    const overId = over.id;

    // Determine the target column based on the drop zone
    const overColumnId = over.data.current?.type === "Column" ? overId : over.data.current?.columnId;

    if (overColumnId) {
      setTasks((tasks) => {
        const activeIndex = tasks.findIndex((task) => task.id === activeId);
        const activeTask = tasks[activeIndex];

        // Update the column if moving to a different one
        if (activeTask.columnId !== overColumnId) {
          activeTask.columnId = overColumnId;

          // If the task is moving to the driver's schedule, update the backend
          if (overColumnId === DRIVER_SCHEDULE) {
            updateTaskSchedule(activeId, DRIVER_SCHEDULE_ID);
          }
        }

        return [...tasks];
      });
    }
  };

  return (
    <div className="mt-20 flex flex-col overflow-hidden rounded-md xl:mt-3">
      <div className="max-w-full">
        <Scrollbars
          autoHide
          renderTrackHorizontal={kanbanRenderTrack}
          renderThumbHorizontal={kanbanRenderThumb}
          renderView={kanbanRenderView}
        >
          <DndContext
            sensors={sensors}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            collisionDetection={rectIntersection}
          >
            <div className="m-auto flex gap-4">
              {columns.map((col) => (
                <SortableContext
                  key={col.id}
                  items={tasks.filter((task) => task.columnId === col.id).map((task) => task.id)}
                >
                  <ColumnContainer
                    column={col}
                    tasks={tasks.filter((task) => task.columnId === col.id)}
                  />
                </SortableContext>
              ))}

            </div>

            {createPortal(
              <DragOverlay>
                {activeTask && <TaskCard task={activeTask} />}
              </DragOverlay>,
              document.body
            )}
          </DndContext>
        </Scrollbars>
      </div>
    </div>
  );
}

export default KanbanBoard;
