import InputField from "components/fields/InputField";
import TextField from "components/fields/TextField";
import DatePickerInput from "components/fields/DatePicker";
import DropDownNew from "components/fields/dropdownNew";
import { useState, useEffect } from "react";

const MachineSelection = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [expectedEndDate, setExpectedEndDate] = useState(new Date());
  const [familyName, setFamilyName] = useState("Smith");
  const [caseName, setCaseName] = useState("Example Case Name");
  const [caseDescription, setCaseDescription] = useState("Example Case Description");
  const [localAuthority, setLocalAuthority] = useState("Oldham Council");
  const [filingDate, setFilingDate] = useState(new Date());
  const [assessmentType, setAssessmentType] = useState(1);

  const assessmentTypes = [
    { label: "PARENT ASSESS", value: 1 },
    { label: "SOCIAL WORK", value: 2 },
    { label: "SIBLING", value: 3 },
    { label: "CONNECTED CARER", value: 4 },
    { label: "OTHER", value: 5 },

  ]
  const handleSelectChange = (event) => {
    setAssessmentType(event.target.value);
  };
  useEffect(() => {
    const storedData = sessionStorage.getItem("caseInformation");
    if (storedData) {
      const data = JSON.parse(storedData);
      setStartDate(new Date(data.start));
      setExpectedEndDate(new Date(data.end));
      setFamilyName(data.family_name);
      setCaseName(data.case_name);
      setCaseDescription(data.case_desc);
      setLocalAuthority(data.local_authority);
    }
  }, []);

  const selection = () => {
    const data = {
      start: startDate,
      end: expectedEndDate,
      family_name: familyName,
      case_name: caseName,
      case_desc: caseDescription,
      local_authority: localAuthority,
      filing_date: filingDate,
      assessment_type: assessmentType,
    };

    sessionStorage.setItem("caseInformation", JSON.stringify(data));
  };

  useEffect(() => {
    console.log({
      familyName,
      caseName,
      caseDescription,
      localAuthority,
      startDate,
      expectedEndDate,
      filingDate,
      assessmentType,
    });
    selection();
  }, [familyName, caseName, caseDescription, localAuthority, startDate, expectedEndDate, filingDate, assessmentType]);

  return (
    <div className="h-full w-full rounded-[20px] px-3 pt-7 md:px-8">
      <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
        Case Information
      </h4>
      <div className="mt-6 grid grid-cols-2 gap-3">
        <div className="col-span-2 md:col-span-1">
          <InputField
            label="Case Name"
            placeholder="Example Case Name"
            id="caseName"
            type="text"
            value={caseName}
            disabled={false}
            onChange={(e) => setCaseName(e.target.value)}
          />
          <InputField
            label="Family Name"
            placeholder="Smith"
            id="familyName"
            type="text"
            value={familyName}
            disabled={false}
            onChange={(e) => setFamilyName(e.target.value)}
          />
          <DatePickerInput
            extra="mb-4"
            label="Start Date"
            placeholder={startDate.toDateString()}
            id="startDate"
            type="text"
            selected={startDate}
            onChange={(date) => setStartDate(date)}
          />
          <DropDownNew
            extra="mb-3"
            label="Assessment Type"
            id="contactType"
            placeholder="Select assessment type"
            options={assessmentTypes}
            value={assessmentType}
            onChange={handleSelectChange}
          />
        </div>
        <div className="col-span-2 md:col-span-1">

          <InputField
            label="Local Authority"
            placeholder="Oldham Council"
            id="localAuthority"
            type="text"
            value={localAuthority}
            disabled={false}
            onChange={(e) => setLocalAuthority(e.target.value)}
          />
          <DatePickerInput
            extra="mb-4"
            label="Filing Date"
            placeholder={filingDate.toDateString()}
            id="expectedEndDate"
            type="text"
            selected={filingDate}
            onChange={(date) => setFilingDate(date)}
          />
          <DatePickerInput
            extra="mb-4"
            label="Hearing Date"
            placeholder={expectedEndDate.toDateString()}
            id="expectedEndDate"
            type="text"
            selected={expectedEndDate}
            onChange={(date) => setExpectedEndDate(date)}
          />
          <TextField
            label="Case Description"
            placeholder="Example Case Description"
            id="caseDescription"
            type="text"
            value={caseDescription}
            disabled={false}
            onChange={(e) => setCaseDescription(e.target.value)}
            rows={6}
          />
        </div>
      </div>
    </div>
  );
};

export default MachineSelection;