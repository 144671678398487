export const documentColumns = [

  {
    Header: 'Document Name',
    accessor: 'document_name',
  },
  {
    Header: 'Uploaded Date',
    accessor: 'document_created_date',

  },
  {
    Header: 'Type',
    accessor: 'document_type_display',
  },

  {
    Header: '',
    accessor: 'button',
  },
];
