import React, { useMemo } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
// Assets
import CardMenu from "components/card/CardMenu";
import { AiOutlinePlus } from "react-icons/ai";
import { IoEyeOutline } from "react-icons/io5";
import Card from "components/card";
import formatDate from "components/actions/ConvertDate";
import { useNavigate } from "react-router-dom";


function padID(id) {
  return String(id).padStart(4, '0');
}
const ManagementTable = (props) => {
  const { columnsData, tableData } = props;
  const navigate = useNavigate();
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const color1 = " text-green-500 font-medium";
  const color2 = " text-red-500 font-medium";
  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    tableInstance;

  return (
    <Card extra={"w-full px-9 py-3 h-full"}>
      <div className="mt-2 flex w-full items-center justify-between px-1">
        <h4 className="font-dm text-xl font-bold text-navy-700 dark:text-white">
          My Outstanding Tasks
        </h4>
        <button
          onClick={() => { navigate("/platform/todo/new") }}
          className="linear flex items-center justify-center rounded-md bg-lightPrimary px-8 py-1 text-lg text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:!bg-navy-700 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20"

        >
          <AiOutlinePlus />
        </button>
      </div>
      {/* tables */}
      <div className="mt-[25px] overflow-x-scroll 2xl:overflow-x-hidden">
        <table {...getTableProps()} className="w-full font-dm text-navy-700">
          <thead className="w-full">
            {headerGroups.map((headerGroup, index) => (
              <tr
                className="items-center border-b border-gray-200 text-base dark:border-white/10"
                {...headerGroup.getHeaderGroupProps()}
                key={index}
              >
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className=" pb-[15px] pl-[5px] text-start text-sm font-bold uppercase tracking-wide text-gray-600"
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()} className="w-full">
            {page.map((row, index) => {
              prepareRow(row);

              return (

                <tr {...row.getRowProps()} key={index} className="items-center">
                  {row.cells.map((cell, index) => {
                    let data = "";
                    if (cell.column.Header === "Title") {
                      data = (
                        <div className="flex items-center text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </div>
                      );
                    } else if (cell.column.Header === "Description") {
                      data = (
                        <div className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </div>
                      );
                    }

                    else if (cell.column.Header === "Type") {
                      data = (
                        <div className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </div>
                      );
                    }
                    else if (cell.column.Header === "Priority") {
                      if (cell.value == 1) {
                        cell.value = "HIGH"
                      }
                      else if (cell.value == 2) {
                        cell.value = "MEDIUM"
                      }
                      else if (cell.value == 3) {
                        cell.value = "LOW"
                      }

                      data = (
                        <div className={`text-sm font-bold text-navy-700 dark:text-white pl-0 ${cell.value === "HIGH" ? "text-red-500" :
                          cell.value === "MEDIUM" ? 'text-orange-500' : cell.value === "MEDIUM" ? "text-green-500" : ""}`}>
                          {cell.value}
                        </div>
                      );
                    } else if (cell.column.Header === "Due Date") {
                      const date = formatDate(cell.value)
                      data = (
                        <div
                          className={`text-sm font-bold pl-0 `}
                        >
                          {date
                          }
                        </div>
                      );
                    }
                    else if (cell.column.Header === "") {
                      data = (
                        <button className="max-h-fit w-full max-w-max cursor-pointer text-xl text-gray-600" onClick={() => { navigate("/platform/todo/details", { state: { todo: row.original } }) }}>
                          <IoEyeOutline />
                        </button>
                      );
                    }
                    return (
                      <td
                        className="mt-[20px] py-[14px] pl-2 lg:pr-2"
                        {...cell.getCellProps()}
                        key={index}
                      >
                        {data}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Card>
  );
};

export default ManagementTable;
