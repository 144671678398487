import React, { useState, useEffect, useRef } from 'react';
import axios from 'axiosInstance';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import InputField from 'components/fields/InputField';
import Card from 'components/card';

const SubmitFilledTemplate = () => {
    const [templateId, setTemplateId] = useState("");
    const [caseId, setCaseId] = useState("");
    const [editor, setEditor] = useState(null);
    const editorContainerRef = useRef(null);
    const access_token = sessionStorage.getItem("access_token");

    useEffect(() => {
        if (editorContainerRef.current && !editor) {
            const quillEditor = new Quill(editorContainerRef.current, {
                theme: 'snow'
            });
            setEditor(quillEditor);
        }
    }, [editor]);

    const fetchTemplateContent = async () => {
        try {
            const headers = {
                "Authorization": `Token ${access_token}`,
            };
            const response = await axios.get(`/document-templates/?template_id=${templateId}`, { headers });
            const templateContent = response.data.templates[0].template_content;
            console.log(templateContent);
            editor.root.innerHTML = templateContent;
        } catch (error) {
            console.error("Error fetching template content:", error);
            toast.error("Error fetching template content");
        }
    };

    useEffect(() => {
        if (templateId) {
            fetchTemplateContent();
        }
    }, [templateId]);

    const submitFilledTemplate = async () => {
        try {
            const filledContent = editor.root.innerHTML;
            const data = {
                template_id: templateId,
                case_id: caseId,
                filled_data: filledContent
            };
            const headers = {
                "Authorization": `Token ${access_token}`,
                "Content-Type": "application/json",
            };

            await axios.post('case/filled-templates/', data, { headers });
            toast.success('Template filled and saved successfully!');
            // Add your own logic here if needed, e.g., navigation
        } catch (error) {
            console.error('Error submitting filled template:', error);
            toast.error('Error submitting filled template');
        }
    };

    return (
        <Card extra={"w-full px-9 py-3 h-full"}>
            <div className="mt-2 flex w-full items-center justify-between px-1">
                <h4 className="font-dm text-xl font-bold text-navy-700 dark:text-white">
                    Fill Document Template
                </h4>
            </div>
            <div className="mt-[25px]">
                <InputField
                    label="Template ID"
                    placeholder="Enter template ID"
                    id="templateId"
                    type="text"
                    onChange={(e) => setTemplateId(e.target.value)}
                />
                <InputField
                    label="Case ID"
                    placeholder="Enter case ID"
                    id="caseId"
                    type="text"
                    onChange={(e) => setCaseId(e.target.value)}
                />
                <div className="pt-4">
                    <div ref={editorContainerRef} id="editor" style={{ height: '300px' }}></div>
                </div>
                <div className="pt-8 pb-8 flex-row flex">
                    <button
                        onClick={() => console.log('Cancel clicked')} // Replace with your own logic
                        className="cursor-pointer rounded-xl bg-blue-950 px-16 py-2 font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:ml-2"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={submitFilledTemplate}
                        className="cursor-pointer rounded-xl bg-blue-950 px-16 py-2 font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:ml-2"
                    >
                        Submit
                    </button>
                </div>
            </div>
            <ToastContainer />
        </Card>
    );
};

export default SubmitFilledTemplate;