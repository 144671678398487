import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axiosInstance";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import CaseInformation from "./components/CaseInformation";
import Invoices from "./components/Invoices";
import Parents from "./components/Parents";
import Todo from "./components/Todo";
import Documents from "./components/Documents";
import Children from "./components/Children";
import Events from "./components/Events";

import { todoColumns } from "./variables/todoColumns";
import { invoiceColumns } from "./variables/invoiceColumns";
import { documentColumns } from "./variables/documentsColumns";
import tableData from "./variables/tableDataMostVisited.json";
import { parentsColumns } from "./variables/parentsColumns";
import { contactLogsColumns } from "./variables/contactLogsColumns";
import Modal from 'react-modal';
import { childrenColumns } from "./variables/childrenColumns";
import ThirdParties from "./components/ThirdParties";
import { thirdPartyColumns } from "./variables/thirdPartyColumns";

import { AiFillExclamationCircle } from "react-icons/ai";
import { BsFillCheckCircleFill } from "react-icons/bs";
import SolidSubtleAlert from "./components/SolidSubtleAlert";
import FormSubmissions from "./components/FormSubmissions";
import { formSubmissionColumns } from "./variables/formSubmissionColumns";
Modal.setAppElement('#root');
const CaseView = () => {
  const location = useLocation();
  const { caseid } = location.state || {};
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [equipment, setEquipment] = useState([]);
  const [todo, setTodo] = useState([]);
  const [events, setEvents] = useState([]);
  const [cases, setCases] = useState([]);
  const case_id = caseid;
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const accessToken = sessionStorage.getItem("access_token");

      if (accessToken) {
        const headers = {
          "Authorization": "Token " + accessToken,
          "Accept": 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        };

        try {
          // Fetch user info
          const userResponse = await axios.get("user/", { headers });
          const casesResponse = await axios.get(`case/?case_id=${case_id}`, { headers });
          setCases(casesResponse.data.cases[0]);
          setEvents(casesResponse.data.cases[0].case_events);
          console.log(casesResponse.data.cases[0]);
          setIsLoading(false);

        } catch (error) {
          console.error("Error fetching data:", error);
          navigate("/platform/error/500");
        }
      } else {
        console.error("No access token found");
        navigate("/auth/login");
      }
    };

    fetchData();
  }, [navigate]);

  if (isLoading) {
    return (
      <div className="h-full w-full rounded-[20px] px-3 pt-7 md:px-8">
        <div className="mt-2 flex w-full items-center justify-between px-1">
          <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
            Loading
          </h4>
        </div>
      </div>
    );
  }

  return (
    <div className="mt-3 flex h-full w-full flex-col gap-[20px] rounded-[20px] xl:flex-row">
      <div className="h-full w-full rounded-[20px]">
        <Tabs>
          <TabList>
            <Tab>Case Information</Tab>
            <Tab>Documents</Tab>
            <Tab>Todo</Tab>
            <Tab>Children</Tab>
            <Tab>Parents</Tab>
            <Tab>Contact Logs</Tab>
            <Tab>Form Submissions</Tab>
            <Tab>3rd Parties</Tab>
            <Tab>Invoices</Tab>
          </TabList>

          <TabPanel style={{ paddingTop: '20px' }}>
            <div >
              <div className={`${cases.time_worked_display != null ? "" : "hidden"}`}>
                <SolidSubtleAlert
                  title="Un-Invoiced Case"
                  description="This case has not been invoiced yet. Please ensure that the case is invoiced before closing it."
                  icon={<AiFillExclamationCircle />}
                  iconColor="text-red-500"
                  closeBg="hover:bg-white/20 text-navy-700 dark:text-white"
                  bg="bg-[#FDE0D0] dark:!bg-navy-700"
                  mb="mb-14"
                />
              </div>


              <CaseInformation cases={cases} />
            </div>
            <div style={{ paddingTop: '20px' }}>
              <Events tableData={events} columnsData={contactLogsColumns} caseID={case_id} />
            </div>

          </TabPanel>
          <TabPanel style={{ paddingTop: '20px' }}>
            <Documents tableData={cases.case_documents} columnsData={documentColumns} caseID={case_id} />
          </TabPanel>
          <TabPanel style={{ paddingTop: '20px' }}>
            <Todo tableData={todo} columnsData={todoColumns} caseID={case_id} />
          </TabPanel>
          <TabPanel style={{ paddingTop: '20px' }}>
            <Children tableData={cases.case_children} columnsData={childrenColumns} caseID={case_id} />
          </TabPanel>
          <TabPanel style={{ paddingTop: '20px' }}>
            <Parents tableData={cases.case_people} columnsData={parentsColumns} caseID={case_id} />
          </TabPanel>
          <TabPanel style={{ paddingTop: '20px' }}>
            <Events tableData={events} columnsData={contactLogsColumns} caseID={case_id} />
          </TabPanel>
          <TabPanel style={{ paddingTop: '20px' }}>
            <FormSubmissions tableData={cases.form_submissions} columnsData={formSubmissionColumns} caseID={case_id} />
          </TabPanel>
          <TabPanel style={{ paddingTop: '20px' }}>
            <ThirdParties tableData={cases.case_third_parties} columnsData={thirdPartyColumns} caseID={case_id} ParentsList={cases.case_people} />
          </TabPanel>
        </Tabs>


      </div>
    </div>
  );
};

export default CaseView;